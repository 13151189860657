import React, { useCallback } from 'react';
import { Button, Select, Modal, Drawer, Row, DatePicker, Col, Spin, Divider, Form, Input } from 'antd';
import { HotKeys } from 'react-hotkeys';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import TableComponent from 'components/table-component';
import { DATE_FORMAT } from 'constants/app-constants';
import { inWords } from 'services/Utils';
import { getTheRoundOffValue } from 'helpers';

const { confirm } = Modal;

const layer1FormCol = {
	labelCol: {
		span: 12,
	},
	wrapperCol: {
		span: 12,
	},
};

const AddInvoicePresentational = ({ state, setState, handleSubmit, columns, loading, tableData,vendors, form,handleVendorSelect }) => {
	const showConfirm = useCallback(() => {
		confirm({
			title: 'Do you want to close this window?',
			icon: <ExclamationCircleOutlined />,
			content: 'You will be lost all the details you have entered here.',
			onOk() {
				setState({ ...state, visible: false });
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}, []);

	const subTotal = parseFloat(tableData?.reduce((acc, data) => acc + data?.whousers + data?.amc + data?.others + data?.lrCharges, 0)).toFixed(2);
	const igst = parseFloat(subTotal * (18 / 100)).toFixed(2);
	const grandTotal = parseFloat(subTotal) + parseFloat(igst);
	const roundOff = getTheRoundOffValue(grandTotal);
	
	// console.log(
	// 	'🚀 ~ file: add-invoice-presentational.js ~ line 31 ~ AddInvoicePresentational ~ roundOff',
	// 	typeof subTotal,
	// 	typeof igst,
	// 	parseFloat(subTotal) + parseFloat(igst),
	// 	roundOff.value,
	// 	roundOff.remain
	// );

	return (
		// <HotKeys id="hotkeys" keyMap={keyMap} handlers={keyHandlers}>
		<Drawer
			maskClosable={false}
			title={`New Invoice`}
			// title={`${editData ? 'Edit' : 'Add New'} Student`}
			placement="right"
			width={'80%'}
			visible={state?.visible}
			destroyOnClose
			className="add_student"
			onClose={showConfirm}
			footer={
				<Row>
					<Col
						xl={{
							span: 8,
							offset: 16,
						}}
						md={12}>
						<Row gutter={[10, 10]} style={{ width: '100%' }} justify="end">
							<Col>
								<Button onClick={() => setState({ ...state, visible: false })}>Cancel</Button>
							</Col>
							<Col>
								<Button loading={loading} type="primary" htmlType="submit" onClick={handleSubmit}>
									Submit
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			}>
			<Row justify="center">
				<Col xl={24} md={24}>
					<TableComponent
						{...{
							columns,
							dataSource: tableData,
							pagination: false,
							footer: () => (
								<Row style={{ fontSize: 14 }}>
									<Col xl={18} md={18}>
										<Col xl={24} md={24} className="bold" style={{ paddingRight: 10 }}>
											Amount in words
										</Col>
										<Col xl={24} md={24}>
											{inWords(roundOff.value)}
										</Col>
									</Col>
									<Col xl={6} md={6}>
										<Row>
											<Col xl={12} md={12}>
												Sub Total
											</Col>
											<Col xl={1} md={1}>
												:
											</Col>
											<Col xl={11} md={11}>
												{subTotal}
											</Col>
										</Row>
										<Row>
											<Col xl={12} md={12}>
												SGST 9%
											</Col>
											<Col xl={1} md={1}>
												:
											</Col>
											<Col xl={11} md={11}>
												{0}
											</Col>
										</Row>
										<Row>
											<Col xl={12} md={12}>
												CGST 9%
											</Col>
											<Col xl={1} md={1}>
												:
											</Col>
											<Col xl={11} md={11}>
												{0}
											</Col>
										</Row>
										<Row>
											<Col xl={12} md={12}>
												IGST 18%
											</Col>
											<Col xl={1} md={1}>
												:
											</Col>
											<Col xl={11} md={11}>
												{igst}
											</Col>
										</Row>
										<Row>
											<Col xl={12} md={12}>
												Round Off (+/-)
											</Col>
											<Col xl={1} md={1}>
												:
											</Col>
											<Col xl={11} md={11}>
												{roundOff?.remain || 0}
											</Col>
										</Row>
										<Row>
											<Col xl={12} md={12}>
												Grand Total
											</Col>
											<Col xl={1} md={1}>
												:
											</Col>
											<Col xl={11} md={11}>
												{roundOff.value}
											</Col>
										</Row>
									</Col>
								</Row>
							),
							title: () => (
								<Row justify="space-between">
									<Col xl={24} md={24}>
										<Form
											name="add-category"
											style={{}}
											// requiredMark={false}
											labelAlign="left"
											form={form}
											// onFinish={!editBatch ? addBatch : handleEdit}
											{...layer1FormCol}>
											<Row gutter={[20, 20]}>
												<Col xl={12} md={12}>
													<Form.Item
														label="Vendor Name"
														name="vendorId"
														// initialValue={editBatch?.vendorId}
														rules={[
															{
																required: true,
																message: 'This Field is required!',
															},
														]}>
														<Select placeholder="select vendor" onChange={(a)=>handleVendorSelect(a)}>
															{vendors?.map((vendor) => (
																<Select.Option value={vendor?._id}>{vendor?.vendorName}</Select.Option>
															))}
														</Select>
													</Form.Item>
													<Form.Item
														label="Billing Address"
														name="billingAddress"
														// initialValue={editBatch?.poNumber}
														rules={[
															{
																required: true,
																message: 'This Field is required!',
															},
														]}>
														<Input.TextArea placeholder="Enter Billing Address" />
													</Form.Item>
													<Form.Item
														label="Notes"
														name="notes"
														// initialValue={editBatch?.poNumber}
														rules={[
															{
																required: false,
																message: 'This Field is required!',
															},
														]}>
														<Input.TextArea placeholder="Enter notes" />
													</Form.Item>
												</Col>
												<Col xl={12} md={12}>
													<Form.Item
														label="PO Number"
														name="poNumber"
														// initialValue={editBatch?.poNumber}
														rules={[
															{
																required: false,
																message: 'This Field is required!',
															},
														]}>
														<Input placeholder="Enter PO NUmber" />
													</Form.Item>
													<Form.Item
														label="GSTIN"
														name="gstin"
														// initialValue={editBatch?.poNumber}
														rules={[
															{
																required: true,
																message: 'This Field is required!',
															},
														]}>
														<Input placeholder="Enter gstin" />
													</Form.Item>
													<Form.Item
														label="Invoice date"
														initialValue={moment()}
														rules={[{ required: true }]}
														name="invoiceDate">
														<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
													</Form.Item>
												</Col>
											</Row>
										</Form>
									</Col>
								</Row>
							),
						}}
					/>
				</Col>
			</Row>
		</Drawer>
		// </HotKeys>
	);
};

export default AddInvoicePresentational;

import React, { useEffect, useMemo, useState } from 'react';
import { Popconfirm, Button, Pagination, Row, Col, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { generatePagination } from 'helpers';
import TableComponent from 'components/table-component';
import AddDrawer from 'pages/master/customer-form';
import { getApi } from '../../redux/sagas/getApiDataSaga';
import { SERVER_IP } from '../../assets/Config';
import { getDateFormat } from '../../services/Utils';
import { deleteApi } from '../../redux/sagas/deleteApiSaga';
import { resetApiStatus } from '../../redux/reducers/globals/globalActions';
import HighlightComponent from '../../components/HighlightComponent';
import { AiOutlineSearch } from 'react-icons/ai';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const Vendors = React.memo(() => {
	const globalRedux = useSelector((state) => state?.globalRedux);
	const dispatch = useDispatch();

	const [vendorAddModal, setVendorAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(globalRedux?.vendors || []);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [editVendor, setEditAsset] = useState(null);

	const getVendor = () => {
		let url = `${SERVER_IP}vendor?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi('GET_VENDORS', url));
	};

	useEffect(() => {
		getVendor();
		generatePagination(tableData);
	}, []);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_VENDOR === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_VENDOR'));
			doIt = true;
		}
		if (doIt) {
			getVendor();
		}
	}, [globalRedux.apiStatus]);

	useEffect(() => {
		setTableData(globalRedux?.vendors || []);
	}, [globalRedux?.vendors]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((asset) => {
			return (
				(asset?.vendorName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(asset?.vendorType || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(asset?.gstin || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(asset?.mobile || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(asset?.email || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(asset?.placeOfSupply || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(asset?.remarks || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditAsset(rowData);
		setVendorAddModal(true);
	};

	const handleStaff = () => {
		setEditAsset(null);
		setVendorAddModal(true);
	};

	const column = [
		{
			title: 'Vendor name',
			dataIndex: 'vendorName',
			key: 'vendorName',
			sorter: (a, b) => a?.vendorName?.localeCompare(b?.vendorName),
			width: 150,
			fixed: 'left',
			render: (value) => <HighlightComponent className="ft-12 bold" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Vendor Type',
			dataIndex: 'vendorType',
			key: 'vendorType',
			sorter: (a, b) => a?.vendorType?.localeCompare(b?.vendorType),
			width: 100,
			fixed: 'left',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'GSTIN',
			dataIndex: 'gstin',
			sorter: (a, b) => a?.gstin - b?.gstin,
			key: 'gstin',
			width: 150,
			render: (value) => <HighlightComponent className="ft-12" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />,
		},
		{
			title: 'Warehouse Rate',
			dataIndex: 'warehouseRate',
			key: 'warehouseRate',
			// sorter: (a, b) => a?.warehouseRate - b?.warehouseRate,
			width: 150,
		},
		{
			title: 'Transport Rate',
			dataIndex: 'transportRate',
			key: 'transportRate',
			// sorter: (a, b) => a?.transportRate - b?.transportRate,
			width: 150,
		},
		{
			title: 'Mobile',
			dataIndex: 'mobile',
			sorter: (a, b) => a?.mobile?.localeCompare(b?.mobile),
			key: 'mobile',
			width: 150,
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			sorter: (a, b) => a?.email?.localeCompare(b?.email),
			width: 200,
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Place Of Supply',
			dataIndex: 'placeOfSupply',
			key: 'placeOfSupply',
			sorter: (a, b) => a?.placeOfSupply?.localeCompare(b?.placeOfSupply),
			width: 150,
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			width: 100,
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Created Date',
			dataIndex: 'createdAt',
			align: 'left',
			width: 180,
			render: (value) => (
				<HighlightComponent className="ft-12" searchWords={[searchKey]} autoEscape={true} textToHighlight={getDateFormat(value)} />
			),
			// render: (value) => getDateFormat(value),
		},
		{
			title: 'Created By',
			dataIndex: 'createdBy',
			align: 'left',
			width: 100,
			render: (value) => value?.firstName,
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'vendorName',
			fixed: 'right',
			width: 100,
			render: (value, row) => {
				return (
					<Row gutter={10} justify="center">
						<Col onClick={() => handleDrawer(row)} className="edit_icon">
							<EditOutlined />
						</Col>
						<Col className="delete_icon">
							<Popconfirm
								title={`Are You Sure to Delete ${value}?`}
								okText="Delete"
								cancelText="No"
								placement="rightTop"
								onConfirm={() => {
									let url = `${SERVER_IP}vendor/${row._id}?orgId=${globalRedux.selectedOrganization._id}`;
									dispatch(deleteApi('DELETE_VENDOR', url));
								}}>
								<CloseOutlined />
							</Popconfirm>
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	return (
		<>
			<Row style={{ padding: '20px 10px' }}>
				<Col xl={24}>
					<TableComponent
						className="custom-table"
						style={{ width: '100%' }}
						columns={column}
						bordered
						scroll={{ x: 1300 }}
						rowKey={(record) => record._id}
						dataSource={filteredData}
						title={() => (
							<Row justify="space-between">
								<Col md={3}>
									<Input
										placeholder="Search"
										suffix={<AiOutlineSearch />}
										style={{ height: '30px' }}
										onChange={({ target: { value } }) => setSearchKey(value)}
									/>
								</Col>
								<Col md={7}></Col>
								<Col>
									<Button type="primary" style={{ height: '30px', width: '100%' }} onClick={() => handleStaff(true)}>
										Add Vendor
									</Button>
								</Col>
							</Row>
						)}
						pagination={{
							current: currentPage,
							pageSize: pageSize,
							position: ['none', 'none'],
						}}
						footer={() => (
							<Row justify="space-between">
								<Col>
									<div>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
									</div>
								</Col>

								<Col md={8}>
									<div style={{ textAlign: 'right' }}>
										<Pagination
											pageSizeOptions={intialPageSizeOptions}
											defaultPageSize={initialPageSize}
											showSizeChanger={true}
											total={filteredData?.length}
											onChange={handleTableChange}
											responsive
										/>
									</div>
								</Col>
							</Row>
						)}
						// onChange={handleTableChange}
					/>
				</Col>
			</Row>
			<AddDrawer {...{ vendorAddModal, setVendorAddModal, getVendor, editVendor }} />
		</>
	);
});

export default Vendors;

import React, { forwardRef } from 'react';
import moment from 'moment';
import { DATE_FORMAT } from 'constants/app-constants';
import Logo from 'assets/images/vellogo.jpeg';
import { inWords } from 'services/Utils';
import './lr-copy-to-print.scss';

const LRCopyToPrint = forwardRef((props, ref) => {
	const { lrNumber, invoiceNumber, invoiceQty, invoiceDate, totalValue } = props?.data || {};
	const amountInWords = inWords(totalValue);
	// const { firstName = '', lastName = '' } = useSelector((state) => state?.loginRedux);
	// console.log('🚀 ~ file: lr-copy-to-print.js:18 ~ LRCopyToPrint ~ props?.data:', props?.data);

	return (
		<div className="lrCopy" ref={ref}>
			<div className="lrCopy-pdf">
				<div className="lrCopy-pdf-container">
					<table bordered cellPadding="0">
						<tr className="lrCopy-top">
							<td className="title" colSpan="4">
								<div className="logo-content">
									<div className="image-container">
										<img alt="logo" src={Logo} />
									</div>
									<div className="content">
										<div className="bold center" style={{ color: '#000', fontSize: 12 }}>
											THIRUCHANDUR MURUGAN THUNAI TRANSPORT
										</div>
										<div className="bold center" style={{ color: '#000', fontSize: 8 }}>
											36/71, VIVEKANANDA NAGAR, THEKKUPALAYAM
										</div>
										<div className="bold center" style={{ color: '#000', fontSize: 8 }}>
											SRI RAMAKRISHNA VIDHYALAYA, COIMBATORE
										</div>
										<div className="bold center" style={{ color: '#000', fontSize: 8 }}>
											TAMILNADU - 641020
										</div>
									</div>
								</div>
							</td>
							<td className="center bold" colSpan="2" rowSpan="3">
								<div className="row-style">
									<div className="checkbox-container">
										<div className="checkbox"></div>
									</div>
									<span className="text">Consignor Copy</span>
								</div>
								<div className="row-style">
									<div className="checkbox-container">
										<div className="checkbox"></div>
									</div>
									<span className="text">Consignee Copy</span>
								</div>
								<div className="row-style">
									<div className="checkbox-container">
										<div className="checkbox"></div>
									</div>
									<span className="text">Account's Copy</span>
								</div>
								<div className="row-style">
									<div className="checkbox-container">
										<div className="checkbox"></div>
									</div>
									<span className="text">Transport Copy</span>
								</div>
								{/* <div className="checkbox">Consignee Copy</div>
								<div className="checkbox">Account Copy</div>
								<div className="checkbox">Transport Copy</div> */}
							</td>
						</tr>
						<tr>
							<td colSpan="4" className="center bold">
								<div>Email: tmttransportcbe@gmail.com, website: www.tmttransport.in</div>
							</td>
						</tr>
						<tr>
							<td colSpan="4" className="center bold">
								<div>GSTIN: 33BCGPS9280R2ZL</div>
							</td>
						</tr>
						<tr style={{ height: 0 }}>
							<td className="no-border">
								<div></div>
							</td>
							<td className="no-border">
								<div></div>
							</td>
							<td className="no-border">
								<div></div>
							</td>
							<td className="no-border">
								<div></div>
							</td>
							<td className="no-border">
								<div></div>
							</td>
							<td className="no-border">
								<div></div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div>FROM</div>
							</td>
							<td colSpan="2" className="bold">
								<div>{props?.data?.loadId?.loadFrom.vendorName}</div>
							</td>
							<td className="bold">
								<div>TO</div>
							</td>
							<td colSpan="2" className="bold">
								<div>{props?.data?.loadId?.loadTo.vendorName}</div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div>Consignor's Address</div>
							</td>
							<td colSpan="2" className="bold">
								<div>
									{Object.keys(props?.data?.loadId?.loadFrom?.billingAddress?.[0] || {})
										.filter((k) => k !== '_id')
										.map(function (k) {
											return props?.data?.loadId?.loadFrom?.billingAddress?.[0][k];
										})
										.filter((k) => k)
										.join(', ') || ''}
								</div>
							</td>
							<td className="bold">
								<div>Consignee's Address</div>
							</td>
							<td colSpan="2" className="bold">
								<div>
									{Object.keys((props?.data?.loadId?.loadTo?.billingAddress?.[0] || {}))
										.filter((k) => k !== '_id')
										.map(function (k) {
											return props?.data?.loadId?.loadTo?.billingAddress?.[0][k];
										})
										.filter((k) => k)
										.join(', ') || ''}
								</div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div>LR Number</div>
							</td>
							<td colSpan="2" className="bold">
								<div>{lrNumber}</div>
							</td>
							<td className="bold">
								<div>Freight</div>
							</td>
							<td colSpan="2" className="bold">
								<div>{props?.data?.loadId?.freight}</div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div>LR Date</div>
							</td>
							<td colSpan="2" className="bold">
								<div>{moment(props?.data?.loadId?.loadInDate).format(DATE_FORMAT.DD_MM_YYYY)}</div>
							</td>
							<td className="bold">
								<div>LR Charges</div>
							</td>
							<td colSpan="2" className="bold">
								<div>45</div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div>Vehicle No</div>
							</td>
							<td colSpan="2" className="bold">
								<div>{props?.data?.loadId?.vehicleId?.regNumber}</div>
							</td>
							<td className="bold">
								<div>Freight KGS</div>
							</td>
							<td colSpan="2" className="bold">
								<div>{}</div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div>In Date</div>
							</td>
							<td colSpan="2" className="bold">
								<div>{moment(props?.data?.loadId?.loadInDate).format(DATE_FORMAT.DD_MM_YYYY)}</div>
							</td>
							<td className="bold">
								<div>Halting Charges</div>
							</td>
							<td colSpan="2" className="bold">
								<div></div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div>In Time</div>
							</td>
							<td colSpan="2" className="bold">
								<div>{moment(props?.data?.loadId?.loadInDate).format('h:mm A')}</div>
							</td>
							<td className="bold">
								<div>Others</div>
							</td>
							<td colSpan="2" className="bold">
								<div></div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div>Out Date</div>
							</td>
							<td colSpan="2" className="bold">
								<div></div>
							</td>
							<td className="bold">
								<div>Sub Total</div>
							</td>
							<td colSpan="2" className="bold">
								<div></div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div>Out Time</div>
							</td>
							<td colSpan="2" className="bold">
								<div></div>
							</td>
							<td className="bold">
								<div>Service Tax</div>
							</td>
							<td colSpan="2" className="bold">
								<div></div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div>Service Tax RC No</div>
							</td>
							<td colSpan="2" className="bold">
								<div>BCGPS 9280 RST 001</div>
							</td>
							<td className="bold">
								<div>Total</div>
							</td>
							<td colSpan="2" className="bold">
								<div></div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div>Invoice No & Date</div>
							</td>
							<td colSpan="2" className="bold center">
								<div>Item Description</div>
							</td>
							<td className="bold center">
								<div>Invoice Qty</div>
							</td>
							<td className="bold center">
								<div>Actual Weight (KG)</div>
							</td>
							<td className="bold center">
								<div>Charged Weight (KG)</div>
							</td>
						</tr>
						<tr>
							<td className="bold ft-14">
								<div style={{ height: 25 }}>
									<div>
										{invoiceNumber} & {moment(invoiceDate).format(DATE_FORMAT.DD_MM_YYYY)}
									</div>
								</div>
							</td>
							<td colSpan="2" className="bold ft-14 center">
								<div style={{ height: 25 }}>
									<div>
										{props?.data?.partNumberId.partNumber} - {props?.data?.partNumberId.sapNumber}
									</div>
								</div>
							</td>
							<td className="bold ft-14 center">
								<div style={{ height: 25 }}>{props?.data?.invoiceQty}</div>
							</td>
							<td className="bold ft-14 center">
								<div style={{ height: 25 }}>{props?.data?.invoiceWeight}</div>
							</td>
							<td className="bold ft-14 center">
								<div style={{ height: 25 }}>{props?.data?.invoiceWeight}</div>
							</td>
						</tr>
						{/* <tr>
							<td className="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td className="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr> */}
						<tr>
							<td className="bold center" rowSpan="2">
								<div style={{ height: 25 }}>E. & O.E.</div>
							</td>
							<td colSpan="2" className="bold center">
								<div style={{ height: 25 }}>TMT Counting OK</div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}>Schaeffler Counting Ok</div>
							</td>
							<td className="bold center" colSpan="2">
								<div style={{ height: 25 }}>Amount In Words</div>
							</td>
						</tr>
						<tr>
							<td colSpan="2" className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td className="bold center" colSpan="2">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td colSpan="6">
								<div>The Consignment herein is booked as per conditions printed overleaf & Subject to Coimbatore Jurisdiction,</div>
							</td>
						</tr>
						<tr>
							<td colSpan="6">
								<div>Goods are booked at owner's risk</div>
							</td>
						</tr>
						<tr>
							<td colSpan="2" className="center bold">
								<div>Seal & Signature of the Consignee</div>
							</td>
							<td colSpan="2" className="center bold">
								<div>Customer's Signature</div>
							</td>
							<td colSpan="2" className="center bold">
								<div>Signature of the Booking Official</div>
							</td>
						</tr>
						<tr>
							<td colSpan="2" style={{ height: 50 }}>
								<div style={{ opacity: 0 }}>1</div>
							</td>
							<td colSpan="2">
								<div style={{ opacity: 0 }}>1</div>
							</td>
							<td colSpan="2">
								<div style={{ opacity: 0 }}>1</div>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	);
});

export default LRCopyToPrint;

import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Input, Button, Divider, Form, Select, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from '../../redux/sagas/postApiDataSaga';
import { resetApiStatus } from '../../redux/reducers/globals/globalActions';

const AddApplicationUsers = ({ handleClose, setApplicationUsersModal, editApplicationUsers }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const globalRedux = useSelector((state) => state.globalRedux);
	const vendors = globalRedux?.vendors;
	const partNumbers = globalRedux?.partNumbers;
	console.log('🚀 ~ file: AddApplicationUsers.jsx ~ line 16 ~ AddApplicationUsers ~ vendors', vendors, partNumbers);
	// const states = useSelector((state) => state.globalRedux?.states);

	const addVendor = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
			device: 'web',
		};
		dispatch(postApi(data, 'ADD_APPLICATION_USERS'));
	};
	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
		};
		let url = `${SERVER_IP}user/${editApplicationUsers._id}?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(putApi(data, 'EDIT_APPLICATION_USERS', url));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_APPLICATION_USERS === 'SUCCESS' || globalRedux.apiStatus.EDIT_APPLICATION_USERS === 'SUCCESS') {
			dispatch(resetApiStatus(editApplicationUsers ? 'EDIT_APPLICATION_USERS' : 'ADD_APPLICATION_USERS'));
			setApplicationUsersModal(false);
			handleClose();
		}
	}, [globalRedux.apiStatus]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading =
		globalRedux.apiStatus.ADD_APPLICATION_USERS === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_APPLICATION_USERS === API_STATUS.PENDING;

	return (
		<Container fluid>
			{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
				<Col>
					<h6 style={{ marginBottom: '0px' }}>{editApplicationUsers ? 'Edit' : 'New'} User</h6>
				</Col>
			</Row> */}
			{/* <Divider /> */}
			<Row className="mt-3 m-2 pt-2">
				<Form
					name="add-vendor"
					style={{}}
					colon={false}
					labelAlign="left"
					form={form}
					onFinish={!editApplicationUsers ? addVendor : handleEdit}
					{...layer1FormCol}>
					<Form.Item
						label="First Name"
						name="firstName"
						initialValue={editApplicationUsers?.firstName}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input style={{ width: '100%' }} placeholder="Enter First Name" />
					</Form.Item>
					<Form.Item
						label="Last Name"
						name="lastName"
						initialValue={editApplicationUsers?.lastName}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input style={{ width: '100%' }} placeholder="Enter Last Name" />
					</Form.Item>
					<Form.Item
						label="Email"
						name="email"
						initialValue={editApplicationUsers?.email}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input style={{ width: '100%' }} placeholder="Enter Email" />
					</Form.Item>
					<Form.Item
						label="Password"
						name="password"
						initialValue={editApplicationUsers?.password}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input style={{ width: '100%' }} placeholder="Enter Password" />
					</Form.Item>
					<Form.Item
						label="Mobile"
						name="mobile"
						initialValue={editApplicationUsers?.mobile}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input style={{ width: '100%' }} placeholder="Enter Mobile" />
					</Form.Item>
					<Form.Item
						label="Role"
						name="accessLevel"
						initialValue={editApplicationUsers?.accessLevel}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Select placeholder="Select Role">
							<Select.Option value={'Manager'}>Manager</Select.Option>
							<Select.Option value={'Staff'}>Staff</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						wrapperCol={{
							offset: 0,
							span: 24,
						}}>
						<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
							<Button danger style={{ width: '49%' }} onClick={() => setApplicationUsersModal(false)}>
								Cancel
							</Button>
							<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
								{editApplicationUsers ? 'Update' : 'Save'}
							</Button>
						</Row>
					</Form.Item>
				</Form>
			</Row>
		</Container>
	);
};

export default AddApplicationUsers;

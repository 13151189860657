import { Toaster } from 'react-hot-toast';
import { ToastContainer } from 'react-toastify';
import useToast from 'redux/sagas/useToast';
import Routes from './routes/Routes';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import './css/theme.scss';
import './css/custom.scss';
import './style.scss';
import './css/antd.css';

function App() {
	const {
		// toast
	} = useToast();
	return (
		<>
			<Routes />
			<Toaster />
			<ToastContainer autoClose={1000} hideProgressBar style={{ width: '400px' }} />
		</>
	);
}

export default App;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, InputNumber, Modal, Row } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import TableComponent from 'components/table-component';
import { sendGetRequest } from 'redux/sagas/utils';
import { SERVER_IP } from 'assets/Config';

const StockSummaryModal = ({ editData, setSelectedRow, handleDispatchQtySubmit }) => {
	const [dataSource, setDataSource] = useState(editData?.stockSummary);
	const [loading, setLoading] = useState(false);
	const globalRedux = useSelector((state) => state.globalRedux);

	useEffect(() => {
		editData?.lrNumber && getLocationDetails();
	}, [editData?.lrNumber]);

	const getLocationDetails = async () => {
		await setLoading(true);
		await setDataSource([]);
		const { data: { data } = { data: [] } } = await sendGetRequest(
			null,
			`${SERVER_IP}stock/location?orgId=${globalRedux.selectedOrganization._id}&lrNumber=${editData?.lrNumber}`
		);
		const dataSource = data.map((data) => ({
			locationId: data?.locationId,
			stockQty: data?.stockQty,
			dispatchedQty: data?.dispatchedQty,
			id: uuidv4(),
		}));
		await setLoading(false);
		await setDataSource([...dataSource]);
	};
	
	const handleQuantityChange = (dispatchedQty, id) => {
		const data = dataSource.map((data) => {
			if (data?.id === id) {
				return {
					...data,
					dispatchedQty,
				};
			}
			return data;
		});
		setDataSource([...data]);
	};

	const columns = [
		{
			title: 'Location Name',
			dataIndex: 'locationId',
			key: 'locationId',
			width: '50%',
			render: (value) => <span>{value?.locationName}</span>,
		},
		{
			title: 'Stock Qty',
			dataIndex: 'stockQty',
			key: 'stockQty',
			width: '20%',
			align: 'center',
		},
		{
			title: 'Dispatch Qty',
			dataIndex: 'dispatchedQty',
			key: 'dispatchedQty',
			width: '30%',
			align: 'center',
			render: (value, row) => (
				<>
					<InputNumber
						className={`${parseInt(value) > parseInt(row?.stockQty) ? 'error' : ''}`}
						value={value}
						onChange={(value) => handleQuantityChange(value, row?.id)}
					/>
					{parseInt(value) > parseInt(row?.stockQty) && (
						<div
							style={{
								fontSize: 8,
								lineHeight: 'normal',
								paddingTop: 5,
							}}
							className="errorText">
							Value should less than Stock Qty
						</div>
					)}
				</>
			),
		},
	];

	const isInValid = React.useMemo(() => {
		let hasError = false;
		dataSource?.map((data) => {
			if (parseInt(data?.dispatchedQty) > parseInt(data?.stockQty)) {
				hasError = true;
			}
		});
		const totalStockDispatch = dataSource?.reduce((acc, curr) => acc + parseInt(curr?.dispatchedQty || 0), 0) || 0;
		console.log('🚀 ~ file: stock-summary-modal.js ~ line 76 ~ isInValid ~ totalStockDispatch', totalStockDispatch);
		return hasError || totalStockDispatch < 1;
	}, [dataSource]);

	return (
		<Modal
			width={'40%'}
			visible={!!editData?._id}
			title="Stock Manage"
			// title={`Invoice - (Total Quantity ${locationDetails?.reduce((acc, curr) => acc + curr.qty, 0) || 0})`}
			okText="Submit"
			okButtonProps={{ disabled: isInValid }}
			onOk={() => handleDispatchQtySubmit(editData?._id, dataSource)}
			// destroyOnClose
			onCancel={() => {
				setSelectedRow(null);
			}}>
			<Row>
				<Col xl={24} md={24}>
					<TableComponent loading={loading} dataSource={dataSource} columns={columns} pagination={false} />
				</Col>
			</Row>
		</Modal>
	);
};

export default StockSummaryModal;

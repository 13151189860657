import React from 'react';
import { Menu, Dropdown, PageHeader as AntHeader } from 'antd';
import { FiUsers } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { applicationLogout } from '../services/LoginServices';
import { useHistory } from 'react-router';
// import logo from '../assets/images/gitlab-logo.jpg';
import userLogo from '../assets/images/user.png';
import { DownOutlined } from '@ant-design/icons';
import { setSelectedOrganization } from '../redux/reducers/globals/globalActions';
import './pageHeaderStyle.scss';
import { Link } from 'react-router-dom';

const PageHeader = () => {
	const history = useHistory();

	const globalRedux = useSelector((state) => state.globalRedux);
	// const selectedOrganization = useSelector((state) => state.globalRedux?.selectedOrganization);
	const loginRedux = useSelector((state) => state.loginRedux);

	const dispatch = useDispatch();

	const profileMenu = (
		<Menu style={{ width: '150px', marginTop: '10px' }}>
			{/* <Menu.Item className="profile-btn" key="1">
				Profile
			</Menu.Item> */}
			<Menu.Item
				className="logout-button"
				id="logout-button"
				key="2"
				onClick={() => {
					applicationLogout(history);
				}}>
				Logout
			</Menu.Item>
		</Menu>
	);

	const organizationMenu = (
		<Menu>
			{globalRedux?.organizations?.map((org, index) => (
				<Menu.Item
					key={org?._id}
					// value={selectedOrganization?._id}
					onClick={() => {
						dispatch(setSelectedOrganization(org));
						setTimeout(() => {
							window.location.reload();
						}, 1000);
					}}>
					{org.orgName}
				</Menu.Item>
			))}
		</Menu>
	);

	const LogoComponent = () => (
		<Link to="/">
			<img className="logo" src="./logo.png" style={{ height: 40 }} alt="logo" />
		</Link>
	);

	return loginRedux.isLogged ? (
		<AntHeader
			className="custom-header"
			title={<LogoComponent />}
			style={{ backgroundColor: '#fff' }}
			extra={[
				<Dropdown overlay={organizationMenu} trigger={['click']}>
					<a style={{ color: 'black', marginRight: '10px' }} onClick={(e) => e.preventDefault()}>
						{globalRedux?.selectedOrganization?.orgName} <DownOutlined />
					</a>
				</Dropdown>,
				// <FiUsers className="pointer-icons page-header-tiles" />,
				<Dropdown overlay={profileMenu} className="profile-dropdown" trigger={['click']} style={{}}>
					<div>
						<div className="name">{`${loginRedux?.firstName} ${loginRedux?.lastName}`}</div>
						<img
							src={userLogo}
							alt="profile"
							className="pointer-icons"
							style={{ borderRadius: '50px', marginRight: '20px' }}
							height="23px"
							width="23px"
						/>
					</div>
				</Dropdown>,
			]}
		/>
	) : null;
};

export default PageHeader;

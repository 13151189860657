import React, { useEffect, useMemo, useState } from 'react';
import { Popconfirm, Button, Pagination, Row, Col, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { generatePagination } from 'helpers';
import TableComponent from 'components/table-component';
import AddDrawer from 'pages/master/customer-form';
import { getApi } from '../../redux/sagas/getApiDataSaga';
import { SERVER_IP } from '../../assets/Config';
import { getDateFormat } from '../../services/Utils';
import { deleteApi } from '../../redux/sagas/deleteApiSaga';
import { resetApiStatus } from '../../redux/reducers/globals/globalActions';
import HighlightComponent from '../../components/HighlightComponent';
import { AiOutlineSearch } from 'react-icons/ai';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const VendorsMapping = React.memo(() => {
	const globalRedux = useSelector((state) => state?.globalRedux);
	const dispatch = useDispatch();

	const [vendorAddMappingModal, setVendorAddMapping] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(globalRedux?.vendors || []);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [editVendorMapping, setEditAsset] = useState(null);

	const getVendorMapping = () => {
		let url = `${SERVER_IP}vendormapping?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi('GET_VENDORS_MAPPING', url));
	};

	useEffect(() => {
		dispatch(getApi('GET_PART_NUMBERS', `${SERVER_IP}partnumber?orgId=${globalRedux.selectedOrganization._id}`));
		dispatch(getApi('GET_VENDORS', `${SERVER_IP}vendor?orgId=${globalRedux.selectedOrganization._id}`));
		getVendorMapping();
		generatePagination(tableData);
	}, []);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_VENDOR_MAPPING === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_VENDOR_MAPPING'));
			doIt = true;
		}
		if (doIt) {
			getVendorMapping();
		}
	}, [globalRedux.apiStatus]);

	useEffect(() => {
		setTableData(globalRedux?.vendorsMapping || []);
	}, [globalRedux?.vendorsMapping]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((asset) => {
			return (
				(asset?.vendorId?.vendorName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(asset?.partNumberId?.partNumber || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(asset?.partNumberId?.sapNumber || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(asset?.remarks || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditAsset(rowData);
		setVendorAddMapping(true);
	};

	const handleStaff = () => {
		setEditAsset(null);
		setVendorAddMapping(true);
	};

	const column = [
		{
			title: 'Vendor name',
			dataIndex: 'vendorId',
			key: 'vendorId',
			fixed: 'left',
			width: 150,
			sorter: (a, b) => a?.vendorId?.vendorName?.localeCompare(b?.vendorId?.vendorName),
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.vendorName}
				/>
			),
		},
		{
			title: 'Part Number',
			dataIndex: 'partNumberId',
			key: 'partNumberId',
			fixed: 'left',
			width: 150,
			sorter: (a, b) => a?.partNumberId?.partNumber - b?.partNumberId?.partNumber,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.partNumber}
				/>
			),
		},
		{
			title: 'Sap Number',
			dataIndex: 'partNumberId',
			key: 'partNumberId',
			fixed: 'left',
			width: 150,
			sorter: (a, b) => a?.partNumberId?.sapNumber - b?.partNumberId?.sapNumber,
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.sapNumber}
				/>
			),
		},
		{
			title: 'Critical Qty',
			dataIndex: 'criticalQty',
			key: 'criticalQty',
			width: 150,
			sorter: (a, b) => a?.criticalQty - b?.criticalQty,
		},
		{
			title: 'Minimum Qty',
			dataIndex: 'minimumQty',
			key: 'minimumQty',
			width: 150,
			sorter: (a, b) => a?.minimumQty - b?.minimumQty,
		},
		{
			title: 'Maximum Qty',
			dataIndex: 'maximumQty',
			key: 'maximumQty',
			width: 150,
			sorter: (a, b) => a?.maximumQty - b?.maximumQty,
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			key: 'remarks',
			width: 150,
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Created Date',
			dataIndex: 'createdAt',
			align: 'left',
			width: 150,
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Created By',
			dataIndex: 'createdBy',
			align: 'left',
			width: 150,
			render: (value) => value?.firstName,
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'vendorName',
			width: 100,
			fixed: 'right',
			render: (value, row) => {
				return (
					<Row gutter={10} justify="center">
						<Col onClick={() => handleDrawer(row)} className="edit_icon">
							<EditOutlined />
						</Col>
						<Col className="delete_icon">
							<Popconfirm
								title={`Are You Sure to Delete?`}
								okText="Delete"
								cancelText="No"
								placement="left"
								onConfirm={() => {
									let url = `${SERVER_IP}vendormapping/${row._id}?orgId=${globalRedux.selectedOrganization._id}`;
									dispatch(deleteApi('DELETE_VENDOR_MAPPING', url));
								}}>
								<CloseOutlined />
							</Popconfirm>
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	return (
		<>
			<Row style={{ padding: '20px 10px' }}>
				<Col xl={24}>
					<TableComponent
						className="custom-table"
						style={{ width: '100%' }}
						columns={column}
						bordered
						rowKey={(record) => record._id}
						scroll={{ x: 1300 }}
						dataSource={filteredData}
						title={() => (
							<Row justify="space-between">
								<Col md={3}>
									<Input
										placeholder="Search"
										suffix={<AiOutlineSearch />}
										style={{ height: '30px'}}
										onChange={({ target: { value } }) => setSearchKey(value)}
									/>
								</Col>
								<Col md={7}></Col>
								<Col>
									<Button type="primary" style={{ height: '30px', width: '100%' }} onClick={() => handleStaff(true)}>
										Create Vendor Mapping
									</Button>
								</Col>
							</Row>
						)}
						pagination={{
							current: currentPage,
							pageSize: pageSize,
							position: ['none', 'none'],
						}}
						footer={() => (
							<Row justify="space-between">
								<Col>
									<div>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
									</div>
								</Col>

								<Col md={8}>
									<div style={{ textAlign: 'right' }}>
										<Pagination
											pageSizeOptions={intialPageSizeOptions}
											defaultPageSize={initialPageSize}
											showSizeChanger={true}
											total={filteredData?.length}
											onChange={handleTableChange}
											responsive
										/>
									</div>
								</Col>
							</Row>
						)}
						// onChange={handleTableChange}
					/>
				</Col>
			</Row>
			<AddDrawer {...{ vendorAddMappingModal, setVendorAddMapping, refreshList: getVendorMapping, editVendorMapping }} />
		</>
	);
});

export default VendorsMapping;

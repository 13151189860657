import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import moment from 'moment';
import { SERVER_IP } from 'assets/Config';
import { ACTIONS, API_STATUS } from 'constants/app-constants';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { putApi } from 'redux/sagas/putApiSaga';
import { getApi } from 'redux/sagas/getApiDataSaga';
import AddReceiptPresentational from './add-receipt-presentational';
import { objToQs } from 'helpers';

const AddReceiptFunctional = ({ state, setState, refreshList }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const invoices = useSelector((state) => state?.invoiceRedux?.invoices);
	console.log("🚀 ~ file: add-receipt-functional.js:18 ~ AddReceiptFunctional ~ invoices:", invoices)
	const vendors = globalRedux?.vendors;
	const vendorIdValue = Form.useWatch('vendorId', form);
	const { classes = [] } = globalRedux;
	const dispatch = useDispatch();

	useEffect(() => {
		if (state?.selectedRow) {
			form.setFieldsValue({
				...state?.selectedRow,
				vendorId: state?.selectedRow?.vendorId?._id,
				invoiceId: state?.selectedRow?.invoiceId?._id,
				receiptDate: moment(state?.selectedRow?.receiptDate),
				amount: state?.selectedRow?.amount,
				paymentMode: state?.selectedRow?.paymentMode,
				remarks: state?.selectedRow?.remarks,
			});
		} else {
			form.resetFields();
		}
	}, [state?.selectedRow]);

	const getInvoices = (vendorId) => {
		let params = objToQs({
			vendorId,
		});
		dispatch(getApi(ACTIONS.GET_INVOICES, `${SERVER_IP}invoice?orgId=${globalRedux.selectedOrganization._id}&${params}`));
	};

	const getVendor = () => {
		let url = `${SERVER_IP}vendor?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi('GET_VENDORS', url));
	};

	useEffect(() => {
		if (state?.visible) {
			getVendor();
		}
	}, [state?.visible]);

	useEffect(() => {
		if (vendorIdValue) {
			getInvoices(vendorIdValue);
		}
	}, [vendorIdValue]);

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_RECEIPT === API_STATUS.SUCCESS || globalRedux.apiStatus.EDIT_RECEIPT_NEW === API_STATUS.SUCCESS) {
			setState({ ...state, visible: false });
			refreshList();
			form.resetFields();
			dispatch(resetApiStatus(state?.selectedRow ? ACTIONS.EDIT_RECEIPT_NEW : ACTIONS.ADD_RECEIPT));
		}
	}, [globalRedux.apiStatus]);

	const handleSubmit = (values) => {
		const request = {
			orgId: globalRedux.selectedOrganization._id,
			...values,
		};
		dispatch(postApi(request, ACTIONS.ADD_RECEIPT));
	};

	const handleEdit = (values) => {
		console.log('🚀 ~ file: add-create-load-functional.js ~ line 69 ~ handleEdit ~ values', values);
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
		};
		let url = `${SERVER_IP}receipt/${state?.selectedRow?._id}`;
		dispatch(putApi(data, ACTIONS.EDIT_RECEIPT_NEW, url));
	};

	const loading = globalRedux.apiStatus.ADD_RECEIPT === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_RECEIPT_NEW === API_STATUS.PENDING;
	const invoiceLoading = React.useMemo(() => globalRedux.apiStatus.GET_INVOICES === API_STATUS.PENDING, [globalRedux.apiStatus]);

	return (
		<AddReceiptPresentational
			{...{
				state,
				setState,
				classes,
				loading,
				form,
				handleSubmit,
				handleEdit,
				vendors,
				invoices: vendorIdValue ? invoices : [],
				invoiceLoading,
			}}
		/>
	);
};

export default AddReceiptFunctional;

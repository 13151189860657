import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
// import { RequiredRegex } from '../../assets/RegexValidations';
import { useSelector, useDispatch } from 'react-redux';
import { postApi } from 'redux/sagas/postApiDataSaga';

const Login = (props) => {
	const loginRedux = useSelector((state) => state.loginRedux);
	const globalRedux = useSelector((state) => state.globalRedux);
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const FormProps = {
		labelCol: {
			span: 8,
		},
		wrapperCol: {
			span: 24,
		},
	};

	useEffect(() => {
		if (!!loginRedux.token && loginRedux.isLogged) {
			props.history.push('/organization');
		}
	}, [loginRedux.token, loginRedux.isLogged, props.history]);

	const login = (data) => {
		dispatch(postApi(data, 'LOGIN'));
	};

	return (
		<Container style={{ height: '100vh' }}>
			<Row style={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
				<Col xl={4} lg={4} style={{ padding: '2%' }}>
					<Card className="box_shadow login_card">
						<Container style={{ padding: 15 }}>
							<Row
								style={{
									width: '100%',
									textAlign: 'center',
									padding: '20px',
									paddingTop: '0px',
								}}>
								<h6 style={{ fontWeight: 'bold', color: '#0a4a8c' }}>Login</h6>
							</Row>
							<Row style={{ margin: 0 }}>
								<Col>
									<Form
										requiredMark={false}
										colon={false}
										layout="vertical"
										labelAlign="left"
										form={form}
										onFinish={login}
										{...FormProps}>
										<Form.Item
											label="Email / Mobile"
											name="email"
											rules={[
												{
													required: true,
													message: 'Please Enter Your Email/Mobile',
												},
											]}>
											<Input />
										</Form.Item>

										<Form.Item
											label="Password"
											name="password"
											rules={[
												{
													required: true,
													message: 'Please Enter Your Password!',
												},
											]}>
											<Input.Password visibilityToggle />
										</Form.Item>

										<Row>
											<Col>
												<Row>
													<Link className="nav-link" to="/forgot-password" style={{ fontSize: '12px' }}>
														Forgot Password?
													</Link>
													<br />
												</Row>
											</Col>
											{/* <Col>
												<Row>
													<Link className="nav-link" to="/register" style={{ fontSize: '12px', textAlign: 'right' }}>
														New User?
													</Link>
													<br />
												</Row>
											</Col> */}
										</Row>

										<Form.Item>
											<Button
												style={{ width: '100%' }}
												type="primary"
												htmlType="submit"
												disabled={globalRedux.apiStatus.LOGIN === 'PENDING'}
												loading={globalRedux.apiStatus.LOGIN === 'PENDING'}>
												Login
											</Button>
										</Form.Item>
									</Form>
								</Col>
							</Row>
						</Container>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Login;

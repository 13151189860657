import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Input, Button, Divider, Form, Select, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from '../../redux/sagas/postApiDataSaga';
import { resetApiStatus } from '../../redux/reducers/globals/globalActions';

const AddCredential = ({ handleClose, editCredential, setCredentialAddModal }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const globalRedux = useSelector((state) => state.globalRedux);
	const users = useSelector((state) => globalRedux?.users);
	console.log('🚀 ~ file: Credentials.jsx ~ line 26 ~ Credentials ~ users', users);

	const addCredential = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
		};
		dispatch(postApi(data, 'ADD_CREDENTIAL'));
	};
	const handleEdit = (values) => {
		let data = {
			...values,
			// orgId: globalRedux.selectedOrganization._id,
		};
		let url = `${SERVER_IP}credential/${editCredential._id}?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(putApi(data, 'EDIT_CREDENTIAL', url));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_CREDENTIAL === 'SUCCESS' || globalRedux.apiStatus.EDIT_CREDENTIAL === 'SUCCESS') {
			dispatch(resetApiStatus(editCredential ? 'EDIT_CREDENTIAL' : 'ADD_CREDENTIAL'));
			setCredentialAddModal(false);
			handleClose();
		}
	}, [globalRedux.apiStatus]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_CREDENTIAL === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_CREDENTIAL === API_STATUS.PENDING;

	return (
		<Container fluid>
			{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
				<Col>
					<h6 style={{ marginBottom: '0px' }}>{editCredential ? 'Edit' : 'New'} Credential</h6>
				</Col>
			</Row> */}
			{/* <Divider /> */}
			<Row className="mt-3 m-2 pt-2">
				<Form
					name="add-credential"
					style={{}}
					colon={false}
					labelAlign="left"
					form={form}
					onFinish={!editCredential ? addCredential : handleEdit}
					{...layer1FormCol}>
					<Form.Item
						label="Credential Name"
						name="credName"
						initialValue={editCredential?.credName}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						label="User Name"
						name="userName"
						initialValue={editCredential?.userName}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						label="Password"
						name="password"
						initialValue={editCredential?.password}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						label="Auth Key"
						name="authkey"
						initialValue={editCredential?.authkey}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						label="URL"
						name="url"
						initialValue={editCredential?.url}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						label="Assigned Users"
						name="assignedUsers"
						initialValue={editCredential?.assignedUsers}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Select mode="multiple" placeholder="select assigned users">
							{users?.map((user) => (
								<Select.Option value={user?._id}>{user?.firstName}</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						wrapperCol={{
							offset: 0,
							span: 24,
						}}>
						<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
							<Button danger style={{ width: '49%' }} onClick={() => setCredentialAddModal(false)}>
								Cancel
							</Button>
							<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
								{editCredential ? 'Update' : 'Save'}
							</Button>
						</Row>
					</Form.Item>
				</Form>
			</Row>
		</Container>
	);
};

export default AddCredential;

import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Input, Button, Divider, Tooltip, Form, Switch, Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from '../../redux/sagas/postApiDataSaga';
import { resetApiStatus } from '../../redux/reducers/globals/globalActions';

const AddBatch = ({ handleClose, editBatch, setBatchAddModal }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const globalRedux = useSelector((state) => state.globalRedux);

	const addBatch = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
		};

		dispatch(postApi(data, 'ADD_BATCH'));
	};
	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
		};
		let url = `${SERVER_IP}batch/${editBatch._id}?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(putApi(data, 'EDIT_BATCH', url));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_BATCH === 'SUCCESS' || globalRedux.apiStatus.EDIT_BATCH === 'SUCCESS') {
			dispatch(resetApiStatus(editBatch ? 'EDIT_BATCH' : 'ADD_BATCH'));
			setBatchAddModal(false);
			handleClose();
		}
	}, [globalRedux.apiStatus]);

	useEffect(() => {
		if (editBatch) {
			form.setFieldsValue({
				displayName: editBatch.displayName,
				isActive: editBatch.isActive,
			});
		}
	}, [editBatch]);
	console.log('🚀 ~ file: AddBatch.jsx ~ line 49 ~ AddBatch ~ editBatch', editBatch);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_BATCH === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_BATCH === API_STATUS.PENDING;

	return (
		<Container fluid>
			{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
				<Col xl={24} md={24}>
					<h6 style={{ marginBottom: '0px' }}>{editBatch ? 'Edit' : 'New'} Batch</h6>
				</Col>
			</Row> */}
			{/* <Divider /> */}
			<Row className="mt-3 m-2 pt-2">
				<Col xl={24} md={24}>
					<Form
						name="add-category"
						style={{}}
						requiredMark={false}
						colon={false}
						labelAlign="left"
						form={form}
						onFinish={!editBatch ? addBatch : handleEdit}
						{...layer1FormCol}>
						<Form.Item
							label="Display Name"
							name="displayName"
							initialValue={editBatch?.displayName}
							rules={[
								{
									required: true,
									message: 'This Field is required!',
								},
							]}>
							<Input />
						</Form.Item>
						<Form.Item
							label="IsActive"
							name="isActive"
							initialValue={editBatch ? editBatch?.isActive : false}
							valuePropName="checked"
							rules={[
								{
									required: true,
									message: 'This Field is required!',
								},
							]}>
							<Switch
								{...{
									...(editBatch?.isActive && { disabled: true }),
								}}
							/>
						</Form.Item>
						<Form.Item
							wrapperCol={{
								offset: 0,
								span: 24,
							}}>
							<Row className="space-between" style={{ paddingTop: 20 }}>
								<Col xl={24} md={24}>
									<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
										{editBatch ? 'Update' : 'Save'}
									</Button>
								</Col>
								<Col xl={24} md={24}>
									<Button danger style={{ width: '49%' }} onClick={() => setBatchAddModal(false)}>
										Cancel
									</Button>
								</Col>
							</Row>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</Container>
	);
};

export default AddBatch;

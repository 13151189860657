import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs } from 'antd';
import AccountBook from 'pages/master-pages/AccountBook';
import Voucher from 'pages/master-pages/Voucher';
import Assets from 'pages/master-pages/Assets';
import Vehicles from 'pages/master-pages/Vehicles';
// import Batch from 'pages/master-pages/Batch';
// import Class from 'pages/master-pages/Classes';
import PartNumbers from 'pages/master-pages/PartNumbers';
import Vendors from 'pages/master-pages/Vendors';
import Credentials from 'pages/master-pages/Credentials';
import Locations from 'pages/master-pages/Locations';
import VendorsMapping from 'pages/master-pages/VendorsMapping';
import ApplicationUsers from 'pages/master-pages/ApplicationUsers';
import { useSelector } from 'react-redux';

const { TabPane } = Tabs;
let MASTER_TAB = [
	{
		tabName: 'Vehicles',
		component: Vehicles,
	},
	{
		tabName: 'Part Numbers',
		component: PartNumbers,
	},
	{
		tabName: 'Vendors',
		component: Vendors,
	},
	{
		tabName: 'Acc Books',
		component: AccountBook,
	},
	{
		tabName: 'Locations',
		component: Locations,
	},
	{
		tabName: 'Voucher Head',
		component: Voucher,
	},
	{
		tabName: 'Assets',
		component: Assets,
	},
	{
		tabName: 'Vendor Mapping',
		component: VendorsMapping,
	},
];

const Master = () => {
	const loginRedux = useSelector((state) => state.loginRedux);
	const [tabs, setTabs] = useState(MASTER_TAB);

	useEffect(() => {
		if (loginRedux?.role === 'Manager' || loginRedux?.role === 'Admin') {
			setTabs([
				...tabs,
				{
					tabName: 'Users',
					component: ApplicationUsers,
				},
				{
					tabName: 'Credentials',
					component: Credentials,
				},
			]);
		}
	}, [loginRedux?.accessLevel]);
	return (
		<Row style={{ padding: '20px 10px' }}>
			<Col xl={24}>
				<Tabs destroyInactiveTabPane defaultActiveKey="0">
					{tabs.map(({ tabName, component }, index) => {
						const Component = component;
						return (
							<TabPane tab={tabName} key={index}>
								<Component />
							</TabPane>
						);
					})}
				</Tabs>
			</Col>
		</Row>
	);
};

export default Master;

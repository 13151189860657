import React from 'react';
import { Pagination, Row, Col, Button, Tabs, Modal, Input } from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';
import TableComponent from 'components/table-component';
import StockSummaryModal from './stock-summary-modal';
import DispatchModal from './dispatch-modal';
import DispatchDetailModal from './dispatch-detail-modal';
import { ReloadOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const LoadOutPresentational = ({
	column,
	filteredData,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	refreshList,
	tableLoading,
	editData,
	setSelectedRow,
	partNumbers,
	selectedPartNumber,
	setSelectedPartNumber,
	rowSelection,
	handleDispatchQtySubmit,
	dispatchModalVisible,
	setDispatchModalVisible,
	selectedRowKeys,
	activeTab,
	setActiveTab,
	selectedViewRow,
	setSelectedViewRow,
	setSearchKey,
	tableBackupData,
}) => {
	const hasData = React.useMemo(
		() =>
			tableBackupData?.filter(
				(data) =>
					selectedRowKeys?.includes(data?._id) &&
					parseInt(data?.stockSummary?.reduce((acc, curr) => acc + parseInt(curr?.dispatchedQty || 0), 0) || 0) > 0
			)?.length > 0,
		[selectedRowKeys, tableBackupData]
	);
	return (
		<Row style={{ padding: '20px 10px' }}>
			<DispatchDetailModal {...{ setSelectedViewRow, selectedViewRow }} />
			<Col xl={24} md={24}>
				<Tabs defaultActiveKey="0" destroyInactiveTabPane onChange={setActiveTab}>
					<TabPane tab={'Dispatch'} key={'0'}>
						<TableComponent
							loading={tableLoading}
							rowSelection={rowSelection}
							className="custom-table"
							style={{ width: '100%' }}
							rowKey={(record) => record._id}
							dataSource={filteredData}
							title={() => (
								<Row justify="space-between">
									<Col xl={8} md={8}>
										{/* <Select
											showSearch
											allowClear
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
												option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											placeholder="Select Partnumber"
											style={{ width: 200 }}
											value={selectedPartNumber}
											onChange={(value) => setSelectedPartNumber(value)}>
											{partNumbers?.map((partNumber) => (
												<Select.Option value={partNumber._id}>{partNumber?.partNumber}</Select.Option>
											))}
										</Select> */}
										<Row gutter={[10, 10]}>
											<Col span={10}>
												<Input
													placeholder="Search"
													suffix={<AiOutlineSearch />}
													// style={{ width: 200 }}
													onChange={({ target: { value } }) => setSearchKey(value)}
												/>
											</Col>
											<Col span={10}>
												<Button type="primary" onClick={refreshList}>
													<ReloadOutlined />
												</Button>
											</Col>
										</Row>
									</Col>
									<Col>
										<Button disabled={!hasData} type="primary" onClick={() => setDispatchModalVisible(true)}>
											{/* {!hasData ? `Sorry you don't have any to proceed` : 'Dispatch'} */}
											Dispatch
										</Button>
									</Col>
								</Row>
							)}
							{...{
								columns: column,
								pagination: { current: currentPage, pageSize: pageSize, position: ['none', 'none'] },
								...(!!filteredData?.length && {
									footer: () => (
										<Row justify="space-between">
											<Col md={16}>
												{!!filteredData?.length &&
													`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
											</Col>
											<Col md={8}>
												<div style={{ textAlign: 'right' }}>
													<Pagination
														pageSizeOptions={intialPageSizeOptions}
														defaultPageSize={initialPageSize}
														showSizeChanger={true}
														total={filteredData?.length}
														onChange={handleTableChange}
														responsive
													/>
												</div>
											</Col>
										</Row>
									),
								}),
							}}
						/>
					</TabPane>
					<TabPane tab={'Dispatched List'} key={'1'}>
						<TableComponent
							loading={tableLoading}
							rowSelection={rowSelection}
							className="custom-table"
							style={{ width: '100%' }}
							rowKey={(record) => record._id}
							dataSource={filteredData}
							title={() => (
								<Row justify="space-between">
									<Col xl={8} md={8}>
										{/* <Select
												showSearch
												allowClear
												optionFilterProp="children"
												filterOption={(input, option) =>
													option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
													option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
												}
												placeholder="Select Partnumber"
												style={{ width: 200 }}
												value={selectedPartNumber}
												onChange={(value) => setSelectedPartNumber(value)}>
												{partNumbers?.map((partNumber) => (
													<Select.Option value={partNumber._id}>{partNumber?.partNumber}</Select.Option>
												))}
											</Select> */}
									</Col>
									<Col>
										{/* <Button disabled={!} type="primary" onClick={() => setDispatchModalVisible(true)}>
												Dispatch
											</Button> */}
									</Col>
								</Row>
							)}
							{...{
								columns: column,
								pagination: { current: currentPage, pageSize: pageSize, position: ['none', 'none'] },
								...(!!filteredData?.length && {
									footer: () => (
										<Row justify="space-between">
											<Col md={16}>
												{!!filteredData?.length &&
													`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
											</Col>
											<Col md={8}>
												<div style={{ textAlign: 'right' }}>
													<Pagination
														pageSizeOptions={intialPageSizeOptions}
														defaultPageSize={initialPageSize}
														showSizeChanger={true}
														total={filteredData?.length}
														onChange={handleTableChange}
														responsive
													/>
												</div>
											</Col>
										</Row>
									),
								}),
							}}
						/>
					</TabPane>
				</Tabs>
			</Col>
			<StockSummaryModal {...{ editData, setSelectedRow, handleDispatchQtySubmit }} />
			<DispatchModal {...{ dispatchModalVisible, setDispatchModalVisible, tableData: tableBackupData, selectedRowKeys, refreshList }} />
		</Row>
	);
};

export default LoadOutPresentational;

import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Button, Tooltip, Popconfirm } from 'antd';
import toast from 'react-hot-toast';
import moment from  'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { EyeOutlined, PrinterOutlined } from '@ant-design/icons';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { sendGetRequest } from 'redux/sagas/utils';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { SERVER_IP } from 'assets/Config';
import { ACTIONS, API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import HighlightComponent from 'components/HighlightComponent';
import { objToQs } from 'helpers';
import { getDateFormat } from 'services/Utils';
import DispatchDetailsToPrint from './dispatch-detail-to-print';
import LoadOutPresentational from './load-out-presentational';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const LoadOutFunctional = React.memo((props) => {
	const componentRef = React.useRef();
	const [state, setState] = useState({
		visible: false,
	});
	const [activeTab, setActiveTab] = useState('0');
	const [selectedPartNumber, setSelectedPartNumber] = useState(null);
	const [searchKey, setSearchKey] = useState('');
	const [dispatchModalVisible, setDispatchModalVisible] = useState(false);
	const [selectedClass, setSelectedClass] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedViewRow, setSelectedViewRow] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const globalRedux = useSelector((state) => state.globalRedux);
	const loadOut = useSelector((state) => state.loadOutRedux?.loadOut);
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [tableData, setTableData] = useState(loadOut || []);
	const [tableBackupData, setBackupTableData] = useState(loadOut || []);
	const partNumbers = globalRedux?.partNumbers;
	const dispatch = useDispatch();

	const getLoadOut = () => {
		if (activeTab === '0') {
			dispatch(
				getApi(
					ACTIONS.GET_LOAD_OUT,
					`${SERVER_IP}stock?${objToQs({
						orgId: globalRedux.selectedOrganization._id,
						partNumberId: selectedPartNumber,
						...(activeTab !== '0' && {
							status: 'Dispatched',
						}),
					})}`
				)
			);
		} else {
			dispatch(
				getApi(
					ACTIONS.GET_LOAD_OUT,
					`${SERVER_IP}dispatch?${objToQs({
						orgId: globalRedux.selectedOrganization._id,
					})}`
				)
			);
		}
		// dispatch(getApi(ACTIONS.GET_LOAD_OUT, `${SERVER_IP}stock?orgId=${globalRedux.selectedOrganization._id}&partNumberId=${selectedPartNumber}`));
		// dispatch(
		// 	getApi(
		// 		ACTIONS.GET_LOAD_OUT,
		// 		`${SERVER_IP}stock?${objToQs({
		// 			orgId: globalRedux.selectedOrganization._id,
		// 			partNumberId: selectedPartNumber,
		// 			...(activeTab !== '0' && {
		// 				status: 'Dispatched',
		// 			}),
		// 		})}`
		// 	)
		// );
	};

	const refreshList = () => {
		// setSelectedRowKeys([]);
		getLoadOut();
	};

	const handleGetStudents = (classId) => {
		setSelectedClass(classId);
	};

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((record) => {
			return (
				(record?.partNumberId?.partNumber || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.partNumberId?.sapNumber || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.vendorId?.vendorName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.invoiceNumber || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(record?.lrNumber || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(moment(record?.invoiceDate).format(DATE_FORMAT.DD_MM_YYYY) || '')?.toString()?.toLowerCase().includes(searchKey.toLowerCase()) 
			);
		});
	}, [tableData, searchKey]);
	
	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_UNLOAD === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_UNLOAD'));
			doIt = true;
		}
		if (doIt) {
			getLoadOut();
		}
	}, [globalRedux.apiStatus]);

	const column = [
		{
			title: 'LR #',
			dataIndex: 'lrNumber',
			align: 'left',
			sorter: (a, b) => a?.lrNumber - b?.lrNumber,
		},
		{
			title: 'Vendor Name',
			dataIndex: 'vendorId',
			key: 'vendorId',
			sorter: (a, b) => a?.vendorId?.vendorName?.localeCompare(b?.vendorId?.vendorName),
			width: '15%',
			// render: (value) => <span>{value?.vendorName}</span>,
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value?.vendorName || ''} />
			),
		},
		{
			title: 'Part Number',
			dataIndex: 'partNumberId',
			key: 'partNumberId',
			sorter: (a, b) => a?.partNumberId?.partNumber?.localeCompare(b?.partNumberId?.partNumber),
			width: '10%',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value?.partNumber || ''} />
			),
		},
		{
			title: 'Sap Number',
			dataIndex: 'partNumberId',
			key: 'partNumberId',
			sorter: (a, b) => a?.partNumberId?.sapNumber?.localeCompare(b?.partNumberId?.sapNumber),
			// render: (value) => <span>{value?.sapNumber}</span>,
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value?.sapNumber || ''} />
			),
		},
		{
			title: 'Invoice Number',
			dataIndex: 'invoiceNumber',
			key: 'invoiceNumber',
			sorter: (a, b) => a?.invoiceNumber - b?.invoiceNumber,
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
			),
		},
		{
			title: 'Invoice Date',
			dataIndex: 'invoiceDate',
			key: 'invoiceDate',
			width: '10%',
			sorter: (a, b) => new Date(a.invoiceDate) - new Date(b.invoiceDate),
			// render: (value) => getDateFormat(value, DATE_FORMAT.DD_MM_YYYY),
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={getDateFormat(value, DATE_FORMAT.DD_MM_YYYY) || ''} />
			),
		},
		{
			title: 'Invoice Weight',
			dataIndex: 'invoiceWeight',
			align: 'left',
			sorter: (a, b) => a?.invoiceWeight - b?.invoiceWeight,
		},
		{
			title: 'Invoice Qty',
			dataIndex: 'invoiceQty',
			align: 'left',
			sorter: (a, b) => a?.invoiceQty - b?.invoiceQty,
		},
		{
			title: 'Stock Qty',
			dataIndex: 'stockQty',
			align: 'left',
			sorter: (a, b) => a?.stockQty - b?.stockQty,
		},
		{
			title: 'Dispatch Qty',
			dataIndex: '_id',
			align: 'center',
			render: (value, row) =>
				selectedRowKeys.includes(value) && (
					<Button onClick={() => handleSelectRow({ _id: row?._id, stockSummary: row?.stockSummary })}>
						{row?.stockSummary?.reduce((acc, curr) => acc + parseInt(curr?.dispatchedQty || 0), 0) || 0}
					</Button>
				),
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'loadNumber',
			width: '5%',
			render: (value, row) => {
				if (activeTab === '0') return null;
				return (
					<Row gutter={10} justify="center">
						<Col
							onClick={() =>
								activeTab === '0' ? handleSelectRow(row) : row?.loadDetails?.length === 0 ? null : setSelectedRecordToPrint(row)
							}
							className="edit_icon">
							{activeTab === '0' ? null : row?.loadDetails?.length === 0 ? (
								<Tooltip placement="left" title={'sorry no load details'}>
									<PrinterOutlined />
								</Tooltip>
							) : (
								<PrinterOutlined />
							)}
						</Col>
					</Row>
				);
			},
		},
	];

	const getDispatchDetails = async (record, isRowView) => {
		const toastId = toast.loading('Fetching details...');
		const {
			data: { data },
		} = await sendGetRequest(null, `${SERVER_IP}dispatch/detail?orgId=${globalRedux.selectedOrganization._id}&dispatchId=${record?._id}`);
		if (data?.length) {
			toast.dismiss(toastId);
			isRowView ? handleViewRow(data) : setSelectedRecordToPrint({
				commonData: record,
				dispatchDetails: data,
			});
		} else {
			toast.error('Sorry no data found!');
		}
	};

	const column2 = [
		{
			title: '#',
			dataIndex: 'dispatchNumber',
			key: 'dispatchNumber',
			sorter: (a, b) => a?.dispatchNumber?.localeCompare(b?.dispatchNumber),
			width: '5%',
			render: (value) => <span>{value}</span>,
		},
		{
			title: 'Vehicle',
			dataIndex: 'vehicleId',
			key: 'vehicleId',
			sorter: (a, b) => a?.vehicleId?.regNumber?.localeCompare(b?.vehicleId?.regNumber),
			width: '12%',
			render: (value) => <span>{value?.regNumber}</span>,
		},
		{
			title: 'Dispatch Date',
			dataIndex: 'dispatchDate',
			key: 'dispatchDate',
			width: '15%',
			sorter: (a, b) => new Date(a.dispatchDate) - new Date(b.dispatchDate),
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Requested Date',
			dataIndex: 'requestedDate',
			key: 'requestedDate',
			width: '15%',
			sorter: (a, b) => new Date(a.requestedDate) - new Date(b.requestedDate),
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Driver Name',
			dataIndex: 'driverName',
			key: 'driverName',
			sorter: (a, b) => a?.driverName - b?.driverName,
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
			),
		},
		{
			title: 'Driver Mobile',
			dataIndex: 'driverMobile',
			key: 'driverMobile',
			sorter: (a, b) => a?.driverMobile - b?.driverMobile,
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			align: 'left',
			sorter: (a, b) => a?.status - b?.status,
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			align: 'left',
			sorter: (a, b) => a?.remarks - b?.remarks,
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'loadNumber',
			width: '10%',
			render: (value, row, index) => {
				if (activeTab === '0') return null;
				return (
					<Row gutter={10} justify="center">
						<Col
							onClick={() => getDispatchDetails(row)}
							// onClick={() =>
							// 	row?.dispatchDetails?.length === 0 ? null : setSelectedRecordToPrint(row)
							// }
							className="edit_icon">
							{activeTab === '0' ? null : row?.dispatchDetails?.length === 0 ? (
								<Tooltip placement="top" title={'sorry no dispatch details'}>
									<PrinterOutlined />
								</Tooltip>
							) : (
								<PrinterOutlined />
							)}
						</Col>
						{/* <Col
							onClick={() => getDispatchDetails(row, true)}
							// onClick={() =>
							// 	row?.dispatchDetails?.length === 0 ? null : handleViewRow(row)
							// }
							className="pay_icon">
							{activeTab === '0' ? null : row?.dispatchDetails?.length === 0 ? (
								<Tooltip placement="top" title={'sorry no dispatch details'}>
									<EyeOutlined />
								</Tooltip>
							) : (
								<EyeOutlined />
							)}
						</Col> */}
					</Row>
				);
			},
		},
	];

	const handleSelectRow = (row) => {
		setSelectedRow(row);
	};

	const handleViewRow = (row) => {
		setSelectedViewRow(row);
	};

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage === 0 ? 1 : currentPage);
		setPageSize(pageSize);
	};

	const handleDispatchQtySubmit = (id, stockSummary) => {
		const data = tableData?.map((data) => {
			return {
				...data,
				...(data?._id === id && { stockSummary }),
			};
		});
		const backupData = tableBackupData?.map((data) => {
			return {
				...data,
				...(data?._id === id && { stockSummary }),
			};
		});
		setTableData(data);
		setBackupTableData(backupData);
		setSelectedRow(null);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const onSelectChange = (newSelectedRowKeys = [], b, c) => {
		if (activeTab === '0') {
			const oldSelectedRowKeys = selectedRowKeys;
			const newId = newSelectedRowKeys.filter((letter) => !oldSelectedRowKeys.includes(letter))?.[0];
			const row = tableData.find((row) => row._id === newId);
			setSelectedRow(row);
		}
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		hideSelectAll: true,
		preserveSelectedRowKeys: true,
		// getCheckboxProps: (record) => {
		// 	if (badStatuses.includes(record.status))
		// 		return {
		// 			disabled: true,
		// 		};
		// },
	};

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	useEffect(() => {
		getLoadOut(selectedPartNumber);
		// setSelectedRowKeys([]);
	}, [selectedPartNumber, activeTab]);

	useEffect(() => {
		setTableData(loadOut);
		setBackupTableData(loadOut);
	}, [loadOut]);

	useEffect(() => {
		selectedRecordToPrint && handlePrint();
	}, [selectedRecordToPrint]);

	useEffect(() => {
		selectedRow &&
			setState({
				...state,
				visible: true,
			});
	}, [selectedRow]);

	useEffect(() => {
		!state?.visible && setSelectedRow(null);
	}, [state?.visible]);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, [componentRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		onAfterPrint: handleAfterPrint,
		documentTitle: 'Receipt',
		removeAfterPrint: true,
	});

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_LOAD_OUT === API_STATUS.PENDING, [globalRedux.apiStatus]);

	return (
		<>
			<div style={{ display: 'none' }}>
				<DispatchDetailsToPrint ref={componentRef} data={selectedRecordToPrint} />
			</div>
			<LoadOutPresentational
				{...{
					column: activeTab === '0' ? column : column2,
					filteredData,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					intialPageSizeOptions,
					initialPageSize,
					currentPage,
					setSearchKey,
					tableLoading,
					rowSelection: activeTab === '0' ? rowSelection : null,
					handleGetStudents,
					state,
					setState,
					setSelectedRow,
					editData: selectedRow,
					partNumbers,
					selectedPartNumber,
					setSelectedPartNumber,
					handleDispatchQtySubmit,
					dispatchModalVisible,
					setDispatchModalVisible,
					selectedRowKeys,
					refreshList,
					activeTab,
					setActiveTab,
					selectedViewRow,
					setSelectedViewRow,
					tableBackupData
				}}
			/>
		</>
	);
});

export default LoadOutFunctional;

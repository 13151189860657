import { call, takeEvery, put } from 'redux-saga/effects';
import { setDashboardData } from 'redux/reducers/dashboard/dashboardAction';
import { setDailyTransaction } from 'redux/reducers/daily-transaction/dailyTransactionAction';
import { setProduction } from 'redux/reducers/production/productionAction';
import { setInvoices } from 'redux/reducers/invoices/invoiceAction';
import { setCustomers } from '../reducers/customers/customerAction';
import { setSuppliers } from '../reducers/supplier/supplierAction';
import { store } from '../store';
import { RESTCallError, sendGetRequest } from './utils';
import {
	setApiStatus,
	setOrganizations,
	setAccountBooks,
	setClasses,
	setAssets,
	setBatch,
	setVoucherHead,
	setVehicles,
	setPartNumbers,
	setStates,
	setVendors,
	setCredentials,
	setUsers,
	setLocations,
	setVendorsMapping,
	setApplicationUsers,
} from '../reducers/globals/globalActions';
import { setItems } from '../reducers/items/action';
import { setProjects, setSelectedProject } from '../reducers/projects/action';
// import { setUsers } from '../reducers/users/userAction';
import { ACTIONS } from '../../constants/app-constants';
import { setAgents } from '../reducers/agent/agentAction';
import { setStudents } from 'redux/reducers/students/studentsActions';
import { setVouchers } from 'redux/reducers/vouchers/voucherAction';
import { setStudentsFees } from 'redux/reducers/studentFees/studentFeesActions';
import { setLoadIn } from 'redux/reducers/load-in/loadInActions';
import { setLoadOut } from 'redux/reducers/load-out/loadOutActions';
import { setStocks } from 'redux/reducers/stocks/stocksActions';
import { setLoads } from 'redux/reducers/loads/loadsActions';
import { setWhInvoices } from 'redux/reducers/wh-invoices/whInvoiceAction';
import { setStocksByLocations } from 'redux/reducers/stocks-by-locations/stocksByLocaitonsActions';
import { setReceipts } from 'redux/reducers/receipts/receiptActions';
import { setOutstandingReports } from 'redux/reducers/outstanding-reports/outstandingReportsActions';
import { setStocksReturns } from 'redux/reducers/stocks-return/stocksReturnActions';

function getApi(apiName, url, extras) {
	return {
		type: 'GET_API_DATA',
		apiName: apiName,
		url: url,
		extras: extras,
	};
}

function* getApiDataSaga() {
	yield takeEvery('GET_API_DATA', getApiDataWorker);
}

function* getApiDataWorker(param) {
	let url = param.url;
	let apiName = param.apiName;
	try {
		store.dispatch(setApiStatus(apiName, 'PENDING'));
		const result = yield call(sendGetRequest, apiName, url);
		if (result.status === 200) {
			yield getApiDataSuccess(result.data, apiName, param.extras);
		} else if (result.status === 'Error') {
			RESTCallError(result, apiName);
		}
	} catch (error) {
		console.log({ ...error });
	}
}

function* getApiDataSuccess(response, apiName, extras) {
	switch (apiName) {
		case 'GET_ALL_ORGANIZATION':
			yield put(setOrganizations(response?.data || []));
			break;
		case 'GET_ALL_PROJECTS':
			yield put(setProjects(response?.data || []));
			break;
		case 'GET_PROJECT_BY_ID':
			yield put(setSelectedProject(response));
			break;
		case 'GET_CUSTOMERS':
			yield put(setCustomers(response?.data || []));
			break;
		case 'GET_ITEMS':
			yield put(setItems(response?.data || []));
			break;
		case 'GET_CLASSES':
			yield put(setClasses(response?.data || []));
			break;
		case 'GET_SUB_ITEMS':
			yield put(setItems(response?.data || []));
			break;
		case 'GET_SUPPLIERS':
			yield put(setSuppliers(response?.data || []));
			break;
		case 'GET_AGENTS':
			yield put(setAgents(response?.data || []));
			break;
		case 'GET_DAILY_TRANSACTION_LIST':
			yield put(setDailyTransaction(response?.data || []));
			break;
		case 'GET_PRODUCTION_LIST':
			yield put(setProduction(response?.data || []));
			break;
		case 'GET_INVOICES':
			yield put(setInvoices(response || []));
			break;
		case 'GET_WH_INVOICES':
			yield put(setWhInvoices(response || {}));
			break;
		case 'GET_VOUCHERS_HEAD':
			yield put(setVoucherHead(response?.data || []));
			break;
		case ACTIONS.GET_ACCOUNT_BOOKS:
			yield put(setAccountBooks(response?.data || []));
			break;
		case 'GET_ASSETS':
			yield put(setAssets(response?.data || []));
			break;
		case 'GET_VEHICLES':
			yield put(setVehicles(response?.data || []));
			break;
		case 'GET_LOADS':
			yield put(setLoads(response?.data || []));
			break;
		case 'GET_RECEIPTS':
			yield put(setReceipts(response?.data || []));
			break;
		case 'GET_LOADS':
			yield put(setLoads(response?.data || []));
			break;
		case 'GET_VENDORS':
			yield put(setVendors(response?.data || []));
			break;
		case 'GET_VENDORS_MAPPING':
			yield put(setVendorsMapping(response?.data || []));
			break;
		case 'GET_APPLICATION_USERS':
			yield put(setApplicationUsers(response?.data || []));
			break;
		case 'GET_CREDENTIALS':
			yield put(setCredentials(response?.data || []));
			break;
		case 'GET_PART_NUMBERS':
			yield put(setPartNumbers(response?.data || []));
			break;
		case 'GET_USERS':
			yield put(setUsers(response?.data || []));
			break;
		case 'GET_STUDENTS':
			yield put(setStudents(response?.data || []));
			break;
		case 'GET_STUDENTS_FEES':
			yield put(setStudentsFees(response?.data || []));
			break;
		case 'GET_VOUCHERS':
			yield put(setVouchers(response?.data || []));
			break;
		case 'GET_LOAD_IN':
			yield put(setLoadIn(response?.data || []));
			break;
		case 'GET_LOAD_OUT':
			yield put(setLoadOut(response?.data || []));
			break;
		case 'GET_STOCKS':
			yield put(setStocks(response?.data || []));
			break;
		case 'GET_STOCKS_RETURNS':
			yield put(setStocksReturns(response?.data || []));
			break;
		case 'GET_OUTSTANDING_REPORTS':
			yield put(setOutstandingReports(response || []));
			break;
		case 'GET_STOCKS_BY_LOCATIONS':
			yield put(setStocksByLocations(response?.data || []));
			break;
		case 'GET_BATCHES':
			yield put(setBatch(response?.data || []));
			break;
		case 'GET_DASHBOARD_DATA':
			yield put(setDashboardData(response || []));
			break;
		case 'GET_STATES':
			yield put(setStates(response?.data || []));
			break;
		case 'GET_LOCATIONS':
			yield put(setLocations(response?.data || []));
			break;
		default:
			break;
	}
	yield put(setApiStatus(apiName, 'SUCCESS'));
}

export { getApi, getApiDataSaga };

import React, { useEffect, useMemo, useState } from 'react';
import { Popconfirm, Button, Pagination, Row, Col, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import TableComponent from 'components/table-component';
import AddDrawer from 'pages/master/customer-form';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { SERVER_IP } from 'assets/Config';
import { getDateFormat } from 'services/Utils';
import { deleteApi } from 'redux/sagas/deleteApiSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import HighlightComponent from 'components/HighlightComponent';
import { AiOutlineSearch } from 'react-icons/ai';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const Locations = React.memo(() => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const [locationsAddModal, setLocationsAddModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(globalRedux?.locations || []);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [editLocation, setEditLocation] = useState(null);
	const dispatch = useDispatch();

	const getLocations = () => {
		let url = `${SERVER_IP}location?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi('GET_LOCATIONS', url));
	};

	useEffect(() => {
		getLocations();
		if (tableData?.length > 0) {
			const tableBtns = document.getElementsByClassName('ant-pagination-item-link');
			const nextBtn = document.getElementsByClassName('ant-pagination-item-link')[tableBtns.length - 1];
			nextBtn.innerHTML = 'Next >';
			nextBtn.style.paddingLeft = '8px';
			nextBtn.style.paddingRight = '8px';
			const prevBtn = document.getElementsByClassName('ant-pagination-item-link')[0];
			prevBtn.innerHTML = '< Previous';
			prevBtn.style.paddingLeft = '8px';
			prevBtn.style.paddingRight = '8px';
		}
	}, []);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_LOCATION === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_LOCATION'));
			doIt = true;
		}
		if (doIt) {
			getLocations();
		}
	}, [globalRedux.apiStatus]);

	useEffect(() => {
		setTableData(globalRedux?.locations || []);
	}, [globalRedux?.locations]);
	console.log('tableData', tableData);
	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((location) => {
			return (
				(location?.locationName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(location?.remarks || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const handleDrawer = (rowData) => {
		setEditLocation(rowData);
		setLocationsAddModal(true);
	};

	const handleStaff = () => {
		setEditLocation(null);
		setLocationsAddModal(true);
	};

	const column = [
		{
			title: 'Location name',
			dataIndex: 'locationName',
			key: 'locationName',
			sorter: (a, b) => a?.locationName?.localeCompare(b?.locationName),
			fixed: 'left',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			align: 'remarks',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value} />
			),
		},
		{
			title: 'Created Date',
			dataIndex: 'createdAt',
			align: 'left',
			render: (value) => getDateFormat(value),
		},
		{
			title: 'Created By',
			dataIndex: 'createdBy',
			align: 'left',
			render: (value) => value?.firstName,
		},
		{
			title: 'Action',
			align: 'center',
			dataIndex: 'locationName',
			render: (value, row) => {
				return (
					<Row gutter={10} justify="center">
						<Col onClick={() => handleDrawer(row)} className="edit_icon">
							<EditOutlined />
						</Col>
						<Col className="delete_icon">
							<Popconfirm
								title={`Are You Sure to Delete ${value}?`}
								okText="Delete"
								cancelText="No"
								onConfirm={() => {
									let url = `${SERVER_IP}location/${row._id}?orgId=${globalRedux.selectedOrganization._id}`;
									dispatch(deleteApi('DELETE_LOCATION', url));
								}}
								placement="rightTop">
								<CloseOutlined />
							</Popconfirm>
						</Col>
					</Row>
				);
			},
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	return (
		<>
			<Row style={{ padding: '20px 10px' }}>
				<Col xl={24}>
					<TableComponent
						className="custom-table"
						style={{ width: '100%' }}
						columns={column}
						bordered
						rowKey={(record) => record._id}
						dataSource={filteredData}
						title={() => (
							<Row justify="space-between">
								<Col md={3}>
									<Input
										placeholder="Search"
										suffix={<AiOutlineSearch />}
										style={{ height: '30px' }}
										onChange={({ target: { value } }) => setSearchKey(value)}
									/>
								</Col>
								<Col md={7}></Col>
								<Col>
									<Button type="primary" style={{ height: '30px', width: '100%' }} onClick={() => handleStaff(true)}>
										Add Location
									</Button>
								</Col>
							</Row>
						)}
						pagination={{
							current: currentPage,
							pageSize: pageSize,
							position: ['none', 'none'],
						}}
						footer={() => (
							<Row justify="space-between">
								<Col>
									<div>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
									</div>
								</Col>
								<Col md={8}>
									<div style={{ textAlign: 'right' }}>
										<Pagination
											pageSizeOptions={intialPageSizeOptions}
											defaultPageSize={initialPageSize}
											showSizeChanger={true}
											total={filteredData?.length}
											onChange={handleTableChange}
											responsive
										/>
									</div>
								</Col>
							</Row>
						)}
						// onChange={handleTableChange}
					/>
				</Col>
			</Row>
			<AddDrawer {...{ locationsAddModal, setLocationsAddModal, getLocations, editLocation }} />
		</>
	);
});

export default Locations;

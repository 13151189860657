import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Input, Button, Divider, Form, Select, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { API_STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { postApi } from '../../redux/sagas/postApiDataSaga';
import { resetApiStatus } from '../../redux/reducers/globals/globalActions';

const AddVendorMapping = ({ handleClose, setVendorAddMapping, editVendorMapping }) => {
	console.log('🚀 ~ file: AddVendorMapping.jsx ~ line 12 ~ AddVendorMapping ~ editVendorMapping', editVendorMapping);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const globalRedux = useSelector((state) => state.globalRedux);
	const vendors = globalRedux?.vendors;
	const partNumbers = globalRedux?.partNumbers;
	console.log('🚀 ~ file: AddVendorMapping.jsx ~ line 16 ~ AddVendorMapping ~ vendors', vendors, partNumbers);
	// const states = useSelector((state) => state.globalRedux?.states);

	const addVendor = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
		};
		dispatch(postApi(data, 'ADD_VENDOR_MAPPING'));
	};
	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
		};
		let url = `${SERVER_IP}vendormapping/${editVendorMapping._id}?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(putApi(data, 'EDIT_VENDOR_MAPPING', url));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_VENDOR_MAPPING === 'SUCCESS' || globalRedux.apiStatus.EDIT_VENDOR_MAPPING === 'SUCCESS') {
			dispatch(resetApiStatus(editVendorMapping ? 'EDIT_VENDOR_MAPPING' : 'ADD_VENDOR_MAPPING'));
			setVendorAddMapping(false);
			handleClose();
		}
	}, [globalRedux.apiStatus]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading =
		globalRedux.apiStatus.ADD_VENDOR_MAPPING === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_VENDOR_MAPPING === API_STATUS.PENDING;

	return (
		<Container fluid>
			{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
				<Col>
					<h6 style={{ marginBottom: '0px' }}>{editVendorMapping ? 'Edit' : 'New'} Mapping</h6>
				</Col>
			</Row> */}
			{/* <Divider /> */}
			<Row className="mt-3 m-2 pt-2">
				<Form
					name="add-vendor"
					style={{}}
					colon={false}
					labelAlign="left"
					form={form}
					onFinish={!editVendorMapping ? addVendor : handleEdit}
					{...layer1FormCol}>
					<Form.Item
						label="Vendor Name"
						name="vendorId"
						initialValue={editVendorMapping?.vendorId?._id}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Select
							placeholder="Select Vendor"
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
							{vendors?.map((data, index) => (
								<Select.Option key={data?._id} value={data?._id}>
									{data?.vendorName}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						label="Part Number"
						name="partNumberId"
						initialValue={editVendorMapping?.partNumberId?._id}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Select
							placeholder="Select Partnumber"
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
							{partNumbers?.map((data, index) => (
								<Select.Option key={data?._id} value={data?._id}>
									{data?.partNumber}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						label="Enter Critical Qty"
						name="criticalQty"
						initialValue={editVendorMapping?.criticalQty}
						rules={[
							{
								required: false,
								message: 'This Field is required!',
							},
						]}>
						<InputNumber style={{ width: '100%' }} placeholder="Enter Critical Qty" />
					</Form.Item>
					<Form.Item
						label="Enter Minimum Qty"
						name="minimumQty"
						initialValue={editVendorMapping?.minimumQty}
						rules={[
							{
								required: false,
								message: 'This Field is required!',
							},
						]}>
						<InputNumber style={{ width: '100%' }} placeholder="Enter Minimum Qty" />
					</Form.Item>
					<Form.Item
						label="Enter Maximum Qty"
						name="maximumQty"
						initialValue={editVendorMapping?.maximumQty}
						rules={[
							{
								required: false,
								message: 'This Field is required!',
							},
						]}>
						<InputNumber style={{ width: '100%' }} placeholder="Enter Maximum Qty" />
					</Form.Item>
					<Form.Item label="Remarks" name="remarks" initialValue={editVendorMapping?.remarks}>
						<Input.TextArea />
					</Form.Item>
					<Form.Item
						wrapperCol={{
							offset: 0,
							span: 24,
						}}>
						<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
							<Button danger style={{ width: '49%' }} onClick={() => setVendorAddMapping(false)}>
								Cancel
							</Button>
							<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
								{editVendorMapping ? 'Update' : 'Save'}
							</Button>
						</Row>
					</Form.Item>
				</Form>
			</Row>
		</Container>
	);
};

export default AddVendorMapping;

import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Input, Button, Divider, Form, Select, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { SERVER_IP } from 'assets/Config';
import { postApi } from '../../redux/sagas/postApiDataSaga';
import { resetApiStatus } from '../../redux/reducers/globals/globalActions';
import { API_STATUS } from 'constants/app-constants';

const AddVehicle = ({ handleClose, editVehicle, setVehicleAddModal }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const globalRedux = useSelector((state) => state.globalRedux);

	const addVehicle = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
		};
		dispatch(postApi(data, 'ADD_VEHICLE'));
	};
	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux.selectedOrganization._id,
		};
		let url = `${SERVER_IP}vehicle/${editVehicle._id}?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(putApi(data, 'EDIT_VEHICLE', url));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_VEHICLE === 'SUCCESS' || globalRedux.apiStatus.EDIT_VEHICLE === 'SUCCESS') {
			dispatch(resetApiStatus(editVehicle ? 'EDIT_VEHICLE' : 'ADD_VEHICLE'));
			setVehicleAddModal(false);
			handleClose();
		}
	}, [globalRedux.apiStatus]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_VEHICLE === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_VEHICLE === API_STATUS.PENDING;

	return (
		<Container fluid>
			{/* <Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
				<Col>
					<h6 style={{ marginBottom: '0px' }}>{editVehicle ? 'Edit' : 'New'} Vehicle</h6>
				</Col>
			</Row> */}
			{/* <Divider /> */}
			<Row className="mt-3 m-2 pt-2">
				<Form
					name="add-vehicle"
					style={{}}
					colon={false}
					labelAlign="left"
					form={form}
					onFinish={!editVehicle ? addVehicle : handleEdit}
					{...layer1FormCol}>
					<Form.Item
						label="Vehicle Name"
						name="vehicleName"
						initialValue={editVehicle?.vehicleName}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						label="Vehicle Type"
						name="vehicleType"
						placeholder="select vehicle name"
						initialValue={editVehicle?.vehicleType}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Select placeholder="select vehicle type">
							<Select.Option value={'Own'}>Own</Select.Option>
							<Select.Option value={'Rent'}>Rent</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						label="Reg. Number"
						name="regNumber"
						initialValue={editVehicle?.regNumber}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						label="Contact"
						name="contact"
						initialValue={editVehicle?.contact}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						label="Opening Balance"
						name="openingBalance"
						initialValue={editVehicle?.openingBalance}
						rules={[
							{
								required: true,
								message: 'This Field is required!',
							},
						]}>
						<InputNumber style={{ width: '100%' }} />
					</Form.Item>
					<Form.Item label="Remarks" name="remarks" initialValue={editVehicle?.remarks}>
						<Input.TextArea />
					</Form.Item>

					<Form.Item
						wrapperCol={{
							offset: 0,
							span: 24,
						}}>
						<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
							<Button danger style={{ width: '49%' }} onClick={() => setVehicleAddModal(false)}>
								Cancel
							</Button>
							<Button loading={loading} style={{ width: '49%' }} type="primary" htmlType="submit">
								{editVehicle ? 'Update' : 'Save'}
							</Button>
						</Row>
					</Form.Item>
				</Form>
			</Row>
		</Container>
	);
};

export default AddVehicle;

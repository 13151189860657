import { toast } from 'react-toastify';
import moment from 'moment';
import { DATE_FORMAT, NOTIFICATION_STATUS_TYPES } from 'constants/app-constants';
import { ToastifyNotification } from 'components/toast-component';

export const getTheRoundOffValue = (value = 0) => {
	let final = 0;
	const roundValue = Math.round(value);
	final = roundValue - value;
	return {
		value: roundValue,
		remain: parseFloat(final).toFixed(2),
		type: final > 0 ? 'round' : 'floor',
	};
};

export const removeEmptyFields = (obj = {}) => {
	const filteredValue = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ''));
	return {
		filteredValue,
		totalLength: Object.keys(obj).length,
		filteredLength: Object.keys(filteredValue).length,
	};
};
export const getLengthOfObj = (obj = {}) => Object.keys(obj).length;

export const generatePagination = (tableData = []) => {
	if (tableData?.length > 0) {
		const tableBtns = document.getElementsByClassName('ant-pagination-item-link');
		const nextBtn = document.getElementsByClassName('ant-pagination-item-link')[tableBtns.length - 1];
		nextBtn.innerHTML = 'Next >';
		nextBtn.style.paddingLeft = '8px';
		nextBtn.style.paddingRight = '8px';
		const prevBtn = document.getElementsByClassName('ant-pagination-item-link')[0];
		prevBtn.innerHTML = '< Previous';
		prevBtn.style.paddingLeft = '8px';
		prevBtn.style.paddingRight = '8px';
	}
};

export const objToQs = (params) =>
	Object.keys(params)
		.map((key) => params[key] && key + '=' + params[key])
		.filter((params) => params)
		.join('&');

export function getNewlyAddedValuesFromArray(a, b) {
	return b.filter((letter) => !a.includes(letter));
}

export const showToast = (title = 'Success', body = '', type = NOTIFICATION_STATUS_TYPES.SUCCESS) => {
	return toast(<ToastifyNotification type={type} title={title} body={body} />);
};

export const exportToJson = (objectData = {}, name=`json_${moment().format(`${DATE_FORMAT.DD_MM_YYYY} h:mm A`)}_${moment().valueOf()}.json`) => {
	let filename = name;
	let contentType = 'application/json;charset=utf-8;';
	const objToDownload = [objectData];
	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(objToDownload)))], { type: contentType });
		navigator.msSaveOrOpenBlob(blob, filename);
	} else {
		var a = document.createElement('a');
		a.download = filename;
		a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(objToDownload));
		a.target = '_blank';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}
};

export const convertObjectToString = (obj = {}) => {
	return (
		Object.keys(obj)
			.filter((k) => k !== '_id')
			.map(function (k) {
				return obj[k];
			})
			.filter((k) => k)
			.join(', ') || ''
	);
};


export const formQueryStringFromObject = (data) =>
	Object.keys(data)
		.filter((key) => data[key] !== '' && data[key] !== undefined)
		.map((key) => key + '=' + data[key])
		.join('&');
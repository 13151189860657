import React from 'react';
import AddLoadInFunctional from './components/add-load-in-functional';
import './styles.scss';

const AddLoadIn = ({ state, setState, refreshList, editData, activeTab }) => {
	console.log("🚀 ~ file: index.js:6 ~ AddLoadIn ~ editData:", editData, activeTab)
	return <AddLoadInFunctional {...{ state, setState, refreshList, editData, activeTab }} />;
};

export default AddLoadIn;

import React, { forwardRef } from 'react';
import moment from 'moment';
import { DATE_FORMAT } from 'constants/app-constants';
import ESign from 'assets/images/eSign.jpeg';
import './lr-copy-to-print.scss';
import { inWords } from 'services/Utils';

const LRCopyToPrint = forwardRef((props, ref) => {
	// const { firstName = '', lastName = '' } = useSelector((state) => state?.loginRedux);
	const { lrNumber, invoiceNumber, invoiceQty, invoiceDate, totalValue } = props?.data || {};
	const amountInWords = inWords(totalValue);
	console.log('🚀 ~ file: lr-copy-to-print.js:18 ~ LRCopyToPrint ~ props?.data:', props?.data);

	return (
		<div class="lrCopy" ref={ref}>
			<div class="lrCopy-pdf">
				<div className="lrCopy-pdf-container">
					<table bordered cellPadding="0">
						<tr class="lrCopy-top">
							<td class="title grey" colSpan="4">
								<div className="content">
									<div className="bold center grey" style={{ color: '#000' }}>
										THIRUCHANDUR MURUGAN THUNAI TRANSPORT
									</div>
									<div className="bold center grey" style={{ color: '#000', fontSize: 8 }}>
										36/71, VIVEKANANDA NAGAR, THEKKUPALAYAM
									</div>
									<div className="bold center grey" style={{ color: '#000', fontSize: 8 }}>
										SRI RAMAKRISHNA VIDHYALAYA, COIMBATORE
									</div>
									<div className="bold center grey" style={{ color: '#000', fontSize: 8 }}>
										TAMILNADU - 641020
									</div>
								</div>
							</td>
							<td class="center bold grey" colSpan="2" rowSpan="3">
								<div className="row-style">
									<div className="checkbox-container">
										<div className="checkbox"></div>
									</div>
									<span className="text">Consignor Copy</span>
								</div>
								<div className="row-style">
									<div className="checkbox-container">
										<div className="checkbox"></div>
									</div>
									<span className="text">Consignee Copy</span>
								</div>
								<div className="row-style">
									<div className="checkbox-container">
										<div className="checkbox"></div>
									</div>
									<span className="text">Account Copy</span>
								</div>
								<div className="row-style">
									<div className="checkbox-container">
										<div className="checkbox"></div>
									</div>
									<span className="text">Transport Copy</span>
								</div>
								{/* <div className="checkbox">Consignee Copy</div>
								<div className="checkbox">Account Copy</div>
								<div className="checkbox">Transport Copy</div> */}
							</td>
						</tr>
						<tr>
							<td colSpan="4" class="center bold grey">
								<div>Email: tmttransportcbe@gmail.com, website: www.tmttransport.in</div>
							</td>
						</tr>
						<tr>
							<td colSpan="4" class="center bold grey">
								<div>GSTIN: 33BCGPS9280R2ZL</div>
							</td>
						</tr>
						<tr style={{ height: 0 }}>
							<td className="no-border">
								<div></div>
							</td>
							<td className="no-border">
								<div></div>
							</td>
							<td className="no-border">
								<div></div>
							</td>
							<td className="no-border">
								<div></div>
							</td>
							<td className="no-border">
								<div></div>
							</td>
							<td className="no-border">
								<div></div>
							</td>
						</tr>
						<tr>
							<td class="bold grey">
								<div>FROM</div>
							</td>
							<td colSpan="2" class="bold">
								<div>{props?.data?.loadId?.loadFrom.vendorName}</div>
							</td>
							<td class="bold grey">
								<div>TO</div>
							</td>
							<td colSpan="2" class="bold">
								<div>{props?.data?.loadId?.loadTo.vendorName}</div>
							</td>
						</tr>
						<tr>
							<td class="bold grey">
								<div>Consignor's Address</div>
							</td>
							<td colSpan="2" class="bold">
								<div>{props?.data?.loadId?.loadFrom.billingAddress}</div>
							</td>
							<td class="bold grey">
								<div>Consignee's Address</div>
							</td>
							<td colSpan="2" class="bold">
								<div>{props?.data?.loadId?.loadTo.billingAddress}</div>
							</td>
						</tr>
						<tr>
							<td class="bold grey">
								<div>LR Number</div>
							</td>
							<td colSpan="2" class="bold">
								<div>{lrNumber}</div>
							</td>
							<td class="bold grey">
								<div>Freight</div>
							</td>
							<td colSpan="2" class="bold">
								<div>{props?.data?.loadId?.freight}</div>
							</td>
						</tr>
						<tr>
							<td class="bold grey">
								<div>LR Date</div>
							</td>
							<td colSpan="2" class="bold">
								<div>{moment(props?.data?.loadId?.loadInDate).format(DATE_FORMAT.DD_MM_YYYY)}</div>
							</td>
							<td class="bold grey">
								<div>LR Charges</div>
							</td>
							<td colSpan="2" class="bold">
								<div>45</div>
							</td>
						</tr>
						<tr>
							<td class="bold grey">
								<div>Vehicle No</div>
							</td>
							<td colSpan="2" class="bold">
								<div>{props?.data?.loadId?.vehicleId?.regNumber}</div>
							</td>
							<td class="bold grey">
								<div>Freight KGS</div>
							</td>
							<td colSpan="2" class="bold">
								<div>{}</div>
							</td>
						</tr>
						<tr>
							<td class="bold grey">
								<div>In Date</div>
							</td>
							<td colSpan="2" class="bold">
								<div>{moment(props?.data?.loadId?.loadInDate).format(DATE_FORMAT.DD_MM_YYYY)}</div>
							</td>
							<td class="bold grey">
								<div>Halting Charges</div>
							</td>
							<td colSpan="2" class="bold">
								<div></div>
							</td>
						</tr>
						<tr>
							<td class="bold grey">
								<div>In Time</div>
							</td>
							<td colSpan="2" class="bold">
								<div>{moment(props?.data?.loadId?.loadInDate).format('h:mm A')}</div>
							</td>
							<td class="bold grey">
								<div>Others</div>
							</td>
							<td colSpan="2" class="bold">
								<div></div>
							</td>
						</tr>
						<tr>
							<td class="bold grey">
								<div>Out Date</div>
							</td>
							<td colSpan="2" class="bold">
								<div></div>
							</td>
							<td class="bold grey">
								<div>Sub Total</div>
							</td>
							<td colSpan="2" class="bold">
								<div></div>
							</td>
						</tr>
						<tr>
							<td class="bold grey">
								<div>Out Time</div>
							</td>
							<td colSpan="2" class="bold">
								<div></div>
							</td>
							<td class="bold grey">
								<div>Service Tax</div>
							</td>
							<td colSpan="2" class="bold">
								<div></div>
							</td>
						</tr>
						<tr>
							<td class="bold grey">
								<div>Service Tax RC No</div>
							</td>
							<td colSpan="2" class="bold">
								<div>BCGPS 9280 RST 001</div>
							</td>
							<td class="bold grey">
								<div>Total</div>
							</td>
							<td colSpan="2" class="bold">
								<div></div>
							</td>
						</tr>
						<tr>
							<td class="bold grey">
								<div>Invoice No & Date</div>
							</td>
							<td colSpan="2" class="bold center grey">
								<div>Item Description</div>
							</td>
							<td class="bold center grey">
								<div>Invoice Qty</div>
							</td>
							<td class="bold center grey">
								<div>Actual Weight (KG)</div>
							</td>
							<td class="bold center grey">
								<div>Charged Weight (KG)</div>
							</td>
						</tr>
						<tr>
							<td class="bold">
								<div style={{ height: 25 }}>
									<div>
										{invoiceNumber} & {moment(invoiceDate).format(DATE_FORMAT.DD_MM_YYYY)}
									</div>
								</div>
							</td>
							<td colSpan="2" class="bold center">
								<div style={{ height: 25 }}>
									<div>
										{props?.data?.partNumberId.partNumber} - {props?.data?.partNumberId.sapNumber}
									</div>
								</div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}>{props?.data?.invoiceQty}</div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}>{props?.data?.invoiceWeight}</div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}>{props?.data?.invoiceWeight}</div>
							</td>
						</tr>
						{/* <tr>
							<td class="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td class="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td class="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td class="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td class="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td class="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td class="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td class="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td class="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td class="bold">
								<div style={{ height: 25 }}></div>
							</td>
							<td colSpan="2" class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
						</tr> */}
						<tr>
							<td class="bold center" rowSpan="2">
								<div style={{ height: 25 }}>E. & O.E.</div>
							</td>
							<td colSpan="2" class="bold center grey">
								<div style={{ height: 25 }}>TMT Counting OK</div>
							</td>
							<td class="bold center grey">
								<div style={{ height: 25 }}>Schaeffler Counting Ok</div>
							</td>
							<td class="bold center grey" colSpan="2">
								<div style={{ height: 25 }}>Amount In Words</div>
							</td>
						</tr>
						<tr>
							<td colSpan="2" class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center">
								<div style={{ height: 25 }}></div>
							</td>
							<td class="bold center" colSpan="2">
								<div style={{ height: 25 }}></div>
							</td>
						</tr>
						<tr>
							<td colSpan="6">
								<div>The Consignment herein is booked as per conditions printed overself & Subject to Coimbatore Jurisdiction,</div>
							</td>
						</tr>
						<tr>
							<td colSpan="6">
								<div>Goods are booked at owner's risk</div>
							</td>
						</tr>
						<tr>
							<td colSpan="2" class="center bold grey">
								<div>Seal & Signature of the Consignee</div>
							</td>
							<td colSpan="2" class="center bold grey">
								<div>Customer's Signature</div>
							</td>
							<td colSpan="2" class="center bold grey">
								<div>Signature of the Booking Official</div>
							</td>
						</tr>
						<tr>
							<td colSpan="2" style={{ height: 50 }}>
								<div style={{ opacity: 0 }}>1</div>
							</td>
							<td colSpan="2">
								<div style={{ opacity: 0 }}>1</div>
							</td>
							<td colSpan="2">
								<div style={{ opacity: 0 }}>1</div>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	);
});

export default LRCopyToPrint;

import React, { useCallback } from 'react';
import { Button, Form, Input, Select, Modal, Drawer, Row, DatePicker, Col, InputNumber } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DATE_FORMAT } from 'constants/app-constants';
import moment from 'moment';

const { confirm } = Modal;

const formItemLayout = {
	labelCol: { span: 24 },
	wrapperCol: { span: 22 },
};

const commissionTypeData = [
	{ label: 'Fixed Rate', value: 'fixedRate' },
	{ label: 'Commission Rate', value: 'commissionRate' },
];

const paymentModeData = ['cash', 'cheque', 'upi', 'neft', 'rtgs', 'imps'];

const AddReceiptPresentational = ({ state, setState, form, handleSubmit, invoiceLoading, loading, handleEdit, vendors, invoices }) => {
	const showConfirm = useCallback(() => {
		confirm({
			title: 'Do you want to close this window?',
			icon: <ExclamationCircleOutlined />,
			content: 'You will be lost all the details you have entered here.',
			onOk() {
				setState({
					...state,
					visible: false,
				});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}, []);

	const isEditMode = !!state?.selectedRow;

	return (
		<Drawer
			maskClosable={false}
			title={`${isEditMode ? 'Edit' : 'Add New'} Receipt`}
			placement="right"
			// width={'40%'}
			visible={state?.visible}
			destroyOnClose
			className="add_student"
			onClose={showConfirm}
			footer={
				<Row>
					<Col
						xl={{
							span: 16,
							offset: 8,
						}}
						md={{
							span: 16,
							offset: 8,
						}}>
						<Row gutter={[10, 10]} style={{ width: '100%' }} justify="end">
							<Col>
								<Button
									onClick={() => {
										setState({
											...state,
											visible: false,
											selectedRow: null,
										});
										form.resetFields();
									}}>
									Cancel
								</Button>
							</Col>
							<Col>
								<Button loading={loading} type="primary" htmlType="submit" onClick={() => form.submit()}>
									{isEditMode ? 'Update' : 'Submit'}
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			}>
			<Row justify="center">
				<Col xl={24} md={24}>
					<Form
						form={form}
						className="form-container"
						{...formItemLayout}
						onFinish={(values) => (state?.selectedRow ? handleEdit(values) : handleSubmit(values))}>
						<Row>
							<Col xl={24} md={24}>
								<Form.Item label="Receipt date" initialValue={moment()} rules={[{ required: true }]} name="receiptDate">
									<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
								</Form.Item>
							</Col>
							<Col xl={24} md={24}>
								<Form.Item label="Vendor" rules={[{ required: true }]} name="vendorId">
									<Select
										showSearch
										filterOption={(input, option) =>
											option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
											option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										onChange={() =>
											form.setFieldsValue({
												invoiceId: null,
											})
										}
										placeholder="select vendor">
										{vendors?.map((vendor) => (
											<Select.Option value={vendor?._id}>{vendor?.vendorName}</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xl={24} md={24}>
								<Form.Item label="Invoice" rules={[{ required: true }]} name="invoiceId">
									<Select
										loading={invoiceLoading}
										showSearch
										filterOption={(input, option) =>
											(option.props.children || '').toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										placeholder="select invoice">
										{invoices?.map((invoice) => (
											<Select.Option value={invoice?._id}>{invoice?.invoiceNumber}</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xl={24} md={24}>
								<Form.Item label="Amount" rules={[{ required: true }]} name="amount">
									<InputNumber placeholder="enter amount" style={{ width: '100%' }} />
								</Form.Item>
							</Col>
							<Col xl={24} md={24}>
								<Form.Item initialValue={'neft'} label="Payment Mode" name="paymentMode" rules={[{ required: false }]}>
									<Select placeholder="select payment mode">
										{paymentModeData?.map((paymentMode) => (
											<Select.Option value={paymentMode}>{(paymentMode || '').toUpperCase()}</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xl={24} md={24}>
								<Form.Item label="Remarks" name="remarks">
									<Input.TextArea />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
		</Drawer>
	);
};

export default AddReceiptPresentational;
